import React, { useEffect, useState } from "react";
import { Box, Chip, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import { formatCurrency } from "../../../utils/formatUtil";
import { grey } from "@mui/material/colors";

type SpendingPartItemProps = {
  categoryName: string;
  spendingMoney: number;
  targetMoney: number;
};

function SpendingPartItem({ categoryName, spendingMoney, targetMoney }: SpendingPartItemProps) {
  const [rate, setRate] = useState(0);
  const [statusConfig, setStatusConfig] = useState({ label: "안정", color: "#4CAF50" });

  useEffect(() => {
    const newRate = (spendingMoney / (targetMoney + 1)) * 100;
    setRate(newRate);
    setStatusConfig(getStatusConfig(newRate));
  }, [spendingMoney, targetMoney]);

  const getStatusConfig = (rate: number) => {
    if (rate >= 100) {
      return { label: "과지출", color: "#F44336" };
    } else if (rate > 80 && rate < 100) {
      return { label: "주의", color: "#FFA000" };
    } else {
      return { label: "안정", color: "#4CAF50" };
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 3,
        overflow: 'hidden',
        my: 2,
        border: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        background: 'linear-gradient(145deg, #ffffff 0%, #f4f4f4 100%)',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        }
      }}
    >
      <Box sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="subtitle1" fontWeight="medium" sx={{ color: grey[700] }}>
            {getCategoryLabel(categoryName)}
          </Typography>
          <Chip
            label={statusConfig.label}
            sx={{
              bgcolor: statusConfig.color,
              color: 'white',
              fontWeight: 'bold',
              fontSize: '0.875rem',
              height: '32px',
              borderRadius: '16px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            }}
          />
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="baseline" mb={1}>
          <Typography variant="h5" fontWeight="bold" sx={{ color: grey[900] }}>
            {formatCurrency(spendingMoney)}원
          </Typography>
          <Typography variant="body2" sx={{ color: grey[600] }}>
            / 목표 {formatCurrency(targetMoney)}원
          </Typography>
        </Stack>

        <LinearProgress
          variant="determinate"
          value={Math.min(rate, 100)}
          sx={{
            height: 10,
            borderRadius: 5,
            bgcolor: grey[200],
            '& .MuiLinearProgress-bar': {
              bgcolor: statusConfig.color,
              transition: 'transform 0.4s ease',
            },
          }}
        />

        <Typography variant="body2" sx={{ color: grey[600], mt: 1 }}>
          목표 대비 {rate.toFixed(1)}% 사용
        </Typography>
      </Box>
    </Paper>
  );
}

export default SpendingPartItem;

const CATEGORY_LABELS: Record<string, string> = {
  CAFE_AND_SNACK: "카페/간식",
  FOOD: "식비",
  LEISURE: "취미/여가",
  LIVING: "생활",
  TRANSPORTATION: "교통/통신"
};

const getCategoryLabel = (category: keyof typeof CATEGORY_LABELS): string => {
  return CATEGORY_LABELS[category] || "기타";
};
