import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { BarChart as BarChartComponent } from '@mui/x-charts'; // 비교 바 차트 컴포넌트 추가
import { useLocation } from 'react-router-dom';
import { getMbtiDetail } from '../../../api/get';
import '../../../style/chart.css';
import '../../../style/box.css';
import CurvedBackgroundTypography from '../CurvedBackgroundTypography';

// Types
interface CompareBarChartData {
  chartType: 'COMPARE_BAR_CHART';
  title: string;
  maxValue: number;
  totalAverage: number;
  myValue: number;
}

interface BarChartData {
  chartType: 'BAR_CHART';
  title: string;
  count: number;
  maxValue: number;
  list: {
    name: string;
    amount: number;
  }[];
}

interface CountListData {
  chartType: 'COUNT_LIST';
  title: string;
  count: number;
  suffix: string;
  list: {
    name: string;
    count: number;
  }[];
}

type ConsumptionData = {
  typeName: string;
  list: (CompareBarChartData | BarChartData | CountListData)[];
};

const theme = createTheme();

const cardStyles = {
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const CompareBarChart: React.FC<CompareBarChartData> = ({ title, maxValue, totalAverage, myValue }) => (
  <Card sx={{ ...cardStyles, display: 'flex', justifyContent: 'center', alignItems: 'center'
    ,padding:'10px'
   }}>
    <CardContent>
    <Typography variant="h6" style={{fontWeight:'700',fontSize:'22px'}}
        align="center" gutterBottom>{title}</Typography>
      <BarChartComponent
        width={350}
        height={300}
        series={[{
          data: [myValue, totalAverage], // 수치 배열로 수정
          color: '#89c41d',
        }]}
        xAxis={[{ scaleType: 'band', data: ['내 소비', '전체 평균'] }]} // 범주를 x축으로 설정
        yAxis={[{ max: maxValue }]}
        margin={{ left: 80, right: 20 }}
      />
    </CardContent>
  </Card>
);

const BarChart: React.FC<BarChartData> = ({ title, list, maxValue }) => (
  <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <BarChartComponent
        width={400}
        height={300}
        series={[{
          data: list.map(item => item.amount),
          color: '#2196f3',
        }]}
        xAxis={[{ scaleType: 'band', data: list.map(item => item.name) }]}
        yAxis={[{ max: maxValue }]}
        margin={{ left: 80, right: 20 }}
      />
    </CardContent>
  </Card>
);

const CountList: React.FC<CountListData> = ({ title, suffix, list }) => (
  <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <List>
        {list.map(item => (
          <ListItem key={item.name} divider>
            <ListItemText primary={item.name} />
            <Typography variant="body2">{`${item.count}${suffix}`}</Typography>
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
);

const Life: React.FC = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const consumptionDetailId = data?.consumptionDetailId;
  const consumptionTypeId = data?.consumptionTypeId;
  const { name } = data?.item || {};

  const [consumptionData, setConsumptionData] = useState<ConsumptionData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMbtiDetail(consumptionTypeId, consumptionDetailId);
        setConsumptionData(response);
      } catch (error) {
        console.error('소비 데이터 로드 실패:', error);
      }
    };

    fetchData();
  }, [consumptionDetailId, consumptionTypeId]);

  if (!consumptionData) {
    return <Typography>로딩 중...</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', padding: 0 }}>
        {/* <Typography variant="h5" gutterBottom>
          "{name}"
        </Typography> */}
        <CurvedBackgroundTypography 
          text={consumptionData.typeName}
          text2={name}
          backgroundImageUrl="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdBea7a%2FbtsJWv3NPPY%2FFJnOr9Veu26Eq2gMFaaMS0%2Fimg.png"
          iconUrl="https://ifh.cc/g/Lav5ns.png"
        />
       {/* <Typography variant="h5" component="h1" gutterBottom style={{fontWeight:'700',
        alignItems: 'center',display: 'flex', flexDirection: 'column'
      }}>{name}</Typography>
      <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 3,marginTop:'20px',backgroundColor:'white' }}> */}
        {consumptionData.list.map((item, index) => (
          <Box key={index} mb={4} >
            {item.chartType === 'COMPARE_BAR_CHART' && (
              <CompareBarChart {...item as CompareBarChartData} />
            )}
            {item.chartType === 'BAR_CHART' && (
              <BarChart {...item as BarChartData} />
            )}
            {item.chartType === 'COUNT_LIST' && (
              <CountList {...(item as CountListData)} />
            )}
          </Box>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default Life;
