import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserInfoComponents from "./components/UserInfoComponents";
import axiosInstance from "../../utils/axiosInstance";
import { getUserInfo } from "../../api/get";

// Define the interface for the user data
interface UserData {
  id: number;
  userKey: string;
  name: string;
  gender: string;
  email: string;
  password: string;
  age: number;
  job: string;
  salary: number;
  region: string;
  point: number;
  accountNo: string;
}

function UserInfoDetailPage() {
  // Use optional chaining for initial state (partial data until API response)
  const [userData, setUserData] = useState<Partial<UserData>>({});
  const navigate = useNavigate();

  // Function to fetch user data from the API
  const fetchUserData = async () => {
    try {
      const response = await getUserInfo();
      setUserData(response);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // useEffect to fetch the data when the component mounts
  useEffect(() => {
    fetchUserData();
  }, []);

  const goToEditPage = () => {
    navigate("/MyPage/EditUserInfo");
  };

  return (
    <div>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <p className="px-5 py-2 font-bold">기본 정보</p>
        <UserInfoComponents name="이름" description={userData.name || "Loading..."} />
        <UserInfoComponents name="이메일" description={userData.email || "Loading..."} />
      </div>
      <div className="p-2"></div>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <p className="px-5 py-2 font-bold">상세 정보</p>
        <UserInfoComponents name="성별" description={userData.gender || "Loading..."} />
        <UserInfoComponents name="나이" description={userData.age ? userData.age.toString() : "Loading..."} />
        <UserInfoComponents name="직업" description={userData.job || "Loading..."} />
        <UserInfoComponents
          name="월급"
          description={userData.salary ? `${userData.salary}만원` : "Loading..."}
        />
        <UserInfoComponents name="사는 지역" description={userData.region || "Loading..."} />
      </div>
      <div className="p-4"></div>
      <div className="flex justify-center items-center">
        <button
          className="p-1 text-sm font-medium text-gray-500 hover:text-gray-800 underline hover:no-underline"
          onClick={goToEditPage}
        >
          나의 정보 수정하기
        </button>
      </div>
    </div>
  );
}

export default UserInfoDetailPage;
