import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getMbtiDetail } from '../../../api/get';
import CurvedBackgroundTypography from '../CurvedBackgroundTypography';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const theme = createTheme();

interface FoodItem {
  name: string;
  amount: number;
}

interface RankItem {
  rank: number;
  name: string;
  count: number;
  percent: number;
}

interface MbtiData {
  typeName: string;
  list: {
    chartType: string;
    title: string;
    count: number;
    maxValue?: number;
    list: FoodItem[] | RankItem[];
  }[];
}

const Food: React.FC = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const consumptionDetailId = data?.consumptionDetailId;
  const consumptionTypeId = data?.consumptionTypeId;
  const { name } = data.item;

  const [mbtiData, setMbtiData] = useState<MbtiData | null>(null);

  useEffect(() => {
    const fetchMbtiData = async () => {
      try {
        const response = await getMbtiDetail(consumptionTypeId, consumptionDetailId);
        setMbtiData(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMbtiData();
  }, [consumptionDetailId]);

  if (!mbtiData) {
    return <CircularProgress />;
  }

  const barChartData = mbtiData.list.find(item => item.chartType === 'BAR_CHART')?.list as FoodItem[];
  const rankData = mbtiData.list.find(item => item.chartType === 'RANK')?.list as RankItem[];

  const chartData = {
    labels: barChartData?.map(item => item.name),
    datasets: [
      {
        label: '많이 먹은 음식',
        data: barChartData?.map(item => item.amount),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: Math.max(...barChartData.map(item => item.amount)),
        ticks: {
          padding: 5, // 눈금과 레이블 사이의 패딩 추가
        },
      },
      x: {
        ticks: {
          padding: 5, // x축 레이블에도 패딩 추가
        },
      },
    },
    plugins: {
      legend: {
        display: false, // 범례 숨기기
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 0,
        bottom: 10, // 그래프 아래쪽에 여백 추가
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <Typography variant="h5" component="h1" gutterBottom style={{fontWeight:'700',
          alignItems: 'center',display: 'flex', flexDirection: 'column'
        }}>
          {name}
        </Typography> */}
      <CurvedBackgroundTypography 
          text={mbtiData.typeName}
          text2={name}
          backgroundImageUrl="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbRDrBk%2FbtsJXtD6dBc%2FCXJs06VpbZyR7q8AwtenB1%2Fimg.png"
          iconUrl="https://ifh.cc/g/2ZhfWX.png"
        />
      <Box className="chart-container" sx={{ maxWidth: '600px', margin: 'auto',backgroundColor:'white',
       }}> {/* 최대 너비 설정 및 가운데 정렬 */}

        <Card className="chart-card" sx={{ height: '350px', width: '90%', mb: 2, margin: '20px auto',boxShadow: 'none' }}> {/* 높이 줄이고 마진 추가 */}
          <CardContent>
            <Typography variant="h6" align="center" gutterBottom>
              {barChartData?.reduce((total, item) => total + item.amount, 0)}건
            </Typography>
            <Box sx={{ height: '300px' }}> {/* 차트 컨테이너 높이 설정 */}
              <Bar data={chartData} options={chartOptions} />
            </Box>
          </CardContent>
        </Card>

        <Box className="ranking-box" sx={{padding:'20px'}}>
          <Card sx={{ 
            width: '100%', 
            maxWidth: '700px', 
            marginBottom: '30px',
            backgroundColor: '#FFE7E7',
            padding: '10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}>
            {rankData?.map((rankItem, index: number) => (
              <CardContent 
                key={rankItem.name} 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center', 
                  borderBottom: index !== rankData.length - 1 ? '1px solid #FDB8B8' : 'none', // 마지막 항목은 선을 그리지 않음
                  paddingBottom: '10px', // 선과 텍스트 간의 간격 조정
                  marginBottom: index !== rankData.length - 1 ? '0px' : '-10px' 
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box className="ranking-medal" sx={{ mr: 1 }}> {/* 메달 아이콘과 텍스트 사이 간격 추가 */}
                    <Typography variant="h5">
                      {rankItem.rank === 1 ? '🥇' : rankItem.rank === 2 ? '🥈' : '🥉'}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Typography variant="h6" style={{fontWeight:'700'}}>{rankItem.name}</Typography>
                    <Typography variant="body1"style={{fontWeight:'500'}}>({rankItem.percent}%)</Typography>
                  </Box>
                </Box>
              </CardContent>
            ))}
          </Card>
        </Box>

      </Box>
    </ThemeProvider>
  );
};

export default Food;
