import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { main } from '../../api/get'; // main 함수 가져오기

interface StatusBadgeProps {
  status: '안전' | '보통' | '주의';
}

const StatusBadge = styled('div')<{ statusColor: string }>(({ statusColor }) => ({
  backgroundColor: statusColor,
  color: '#fff',
  width: '30px',
  height: '20px',
  borderRadius: '4px',
  fontSize: '0.8rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SmallBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case '안전':
        return '#2196f3';
      case '보통':
        return '#ffeb3b';
      case '주의':
        return '#f44336';
      default:
        return '#757575';
    }
  };

  return (
    <StatusBadge statusColor={getStatusColor(status)}>
      {status}
    </StatusBadge>
  );
};

const CentralCircle = styled('div')(() => ({
  width: '120px',
  height: '120px',
  borderRadius: '50%',
  backgroundColor: '#ECECEC',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'rgba(0, 0, 0, 1)', // 검정색(투명도 없음)
  fontSize: '1.2rem',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #B4AEAE',
  boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)',
  textAlign: 'center', 
  whiteSpace: 'pre-line',
  fontWeight: '700',
  opacity: '1', // 전체 투명도 제거
  lineHeight: '24px',
}));


const SmallCircle = styled('div')(() => ({
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  overflow: 'hidden',
  cursor: 'pointer',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  '&:hover .hover-text': {
    opacity: 1,
  },
}));

const CircleImage = styled('img')<{ borderColor: string }>(({ borderColor }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  border: `3.5px solid ${borderColor}`, // 전달받은 색상으로 설정
}));

const HoverText = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0,
  transition: 'opacity 0.3s ease',
}));

const CircleContainer = styled('div')(() => ({
  position: 'relative',
  width: '300px',
  height: '300px',
  margin: '0 auto',
}));

interface MbtiItem {
  consumptionTypeId: number;
  myType: string;
  imgUrl: string;
}

const Analysis: React.FC = () => {
  const navigate = useNavigate();
  const [mbtiData, setMbtiData] = useState<MbtiItem[]>([]); // MbtiItem 타입 지정

  // 동그라미 클릭 시 카테고리별 경로로 이동하는 함수
  const handleCircleClick = (category: string, data: MbtiItem) => {
    navigate(`/Analysis/${category}`, { state: { data } });
  };

  useEffect(() => {
    main()
      .then(response => {
        setMbtiData(response.list); 
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {/* 상단 영역 */}
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',
         margin:'20px',marginBottom: '0px',marginLeft : '20px',marginRight: '20px' }}>
          <h5 style={{ marginBottom: '0',color: '#8F8F8F',fontSize:'18px' }}>사용자님의 종합소비검사</h5>
          <div style={{ display: 'flex', gap: '5px' }}>
            <SmallBadge status="안전" />
            <SmallBadge status="보통" />
            <SmallBadge status="주의" />
          </div>
        </div>
        <h3 style={{ marginBottom: '80px', marginLeft: '20px', marginTop: '0',
          fontSize : '25px',fontWeight: 'bold', 
         }}>카테고리별 요약</h3>
      </div>

      {/* 카테고리 동그라미 영역 */}
      <div style={{ marginBottom: '100px' }}>
        <CircleContainer>
          <CentralCircle>나의<br />소비 유형</CentralCircle>

          {mbtiData.length > 0 && (
            <>
              <SmallCircle
                onClick={() => handleCircleClick('food', mbtiData[0])}
                style={{ top: '15%', left: '25%', transform: 'translate(-50%, -50%)' }}
              >
                <CircleImage src={mbtiData[0]?.imgUrl || ''} alt="식비" borderColor="#F69292"/>
                <HoverText className="hover-text">식비</HoverText>
              </SmallCircle>
              <SmallCircle
                onClick={() => handleCircleClick('life', mbtiData[3])}
                style={{ top: '15%', left: '75%', transform: 'translate(-50%, -50%)' }}
              >
                <CircleImage src={mbtiData[3]?.imgUrl || ''} alt="생활"borderColor="#AED286"/>
                <HoverText className="hover-text">생활</HoverText>
              </SmallCircle>
              <SmallCircle
                onClick={() => handleCircleClick('cafe', mbtiData[4])}
                style={{ top: '60%', left: '10%', transform: 'translate(-50%, -50%)' }}
              >
                <CircleImage src={mbtiData[4]?.imgUrl || ''} alt="카페/간식" borderColor="#FFE68B"/>
                <HoverText className="hover-text">카페/간식</HoverText>
              </SmallCircle>
              <SmallCircle
                onClick={() => handleCircleClick('hobby', mbtiData[2])}
                style={{ top: '60%', left: '90%', transform: 'translate(-50%, -50%)' }}
              >
                <CircleImage src={mbtiData[2]?.imgUrl || ''} alt="취미/여가" borderColor="#C49FF4"/>
                <HoverText className="hover-text">취미</HoverText>
              </SmallCircle>
              <SmallCircle
                onClick={() => handleCircleClick('transport', mbtiData[1])}
                style={{ top: '95%', left: '50%', transform: 'translate(-50%, -50%)' }}
              >
                <CircleImage src={mbtiData[1]?.imgUrl || ''} alt="교통/통신" borderColor="#6FA0FF"/>
                <HoverText className="hover-text">교통/통신</HoverText>
              </SmallCircle>
            </>
          )}
        </CircleContainer>
      </div>

      {/* 하단 영역 */}
      <div style={{ textAlign: 'center' }}>
        항목별 <span style={{ fontWeight: 'bold' }}>자세한 결과와 관리 솔루션</span>을
        <br />
        확인해 보세요!
      </div>
    </>
  );
};

export default Analysis;
