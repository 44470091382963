import React, { useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box } from '@mui/material';
import '../../../style/chart.css';
import '../../../style/box.css';
import { useLocation } from 'react-router-dom';
import { getMbtiDetail } from '../../../api/get';
import { CircularProgress } from '@mui/material';
import CurvedBackgroundTypography from '../CurvedBackgroundTypography';

const theme = createTheme();

interface FoodItem {
  name: string;
  percent: number;
}

interface MbtiData {
  typeName: string;
  list: {
    chartType: string;
    title: string;
    count: number;
    suffix?: string;
    list: FoodItem[];
  }[];
}

const Food: React.FC = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const consumptionDetailId = data?.consumptionDetailId;
  const consumptionTypeId = data?.consumptionTypeId;
  const { name } = data.item;

  const [mbtiData, setMbtiData] = useState<MbtiData | null>(null);

  useEffect(() => {
    const fetchMbtiData = async () => {
      try {
        const response = await getMbtiDetail(consumptionTypeId, consumptionDetailId);
        setMbtiData(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMbtiData();
  }, [consumptionDetailId]);

  if (!mbtiData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const pieChartData = mbtiData.list[0].list.map((item: FoodItem) => ({
    id: item.name,
    value: item.percent,
    label: `${item.name} (${item.percent}%)`,
  }));

  const emojiMap: { [key: string]: string } = {
    '햄버거': '🍔', 
    '중식': '🥟', 
    '쌀국수': '🍜', 
    '족발/보쌈': '🐷', 
    '초밥/일식': '🍣',
    '서양음식': '🍝',
    '치킨':'🍗',
    '피자':'🍕',
    '그릴바베큐':'🍖',
    '간식':'🍭',
    '아시안':'🍚',
    '스튜/스프':'🥣',
  };
  

  const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

  return (
    <ThemeProvider theme={theme}>
                {/* <Typography variant="h5" component="h1" gutterBottom style={{fontWeight:'700',
        alignItems: 'center',display: 'flex', flexDirection: 'column'
      }}>
          {name}
        </Typography>  */}
      <CurvedBackgroundTypography 
        text={mbtiData.typeName}
        text2={name}
        backgroundImageUrl="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbRDrBk%2FbtsJXtD6dBc%2FCXJs06VpbZyR7q8AwtenB1%2Fimg.png"
        iconUrl="https://ifh.cc/g/0MPYjo.png"
      />

        
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px', padding: '0 20px'
        ,backgroundColor:'white'
       }}>

        <Card sx={{ width: '100%', maxWidth: '700px', padding: '20px',boxShadow: 'none' }}> 
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ width: '60%', display: 'flex', justifyContent: 'center',marginLeft: '20px'
          ,padding: 0
           }}>
              <PieChart
                series={[
                  {
                    data: pieChartData,
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 2,
                    cornerRadius: 5,
                    startAngle: -90,
                  },
                ]}
                width={300}
                height={300}
                colors={colors}
                slotProps={{
                  legend: { hidden: true },
                }}
              />
            </Box>
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'
             }}>
              {pieChartData.map((item, index) => (
                <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', margin: '3px 0' }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: colors[index % colors.length],
                      marginRight: '10px',
                    }}
                  />
                  <Typography variant="body2">{item.label}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
        
        {/* </Card> */}

        <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'center' }}>
          이번달에 배달 어플 총 <strong>{mbtiData.list[1].count}</strong>{mbtiData.list[1].suffix} 사용하셨습니다.
        </Typography>
        <Card sx={{ 
            width: '100%', 
            maxWidth: '700px', 
            marginBottom: '30px',
            backgroundColor: '#FFE7E7',
            padding: '10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }}>
          {mbtiData.list[1].list.map((item: any, index: number) => (
            
            <CardContent 
              key={item.name} 
              sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                borderBottom: index !== mbtiData.list[1].list.length - 1 ? '1px solid #FDB8B8' : 'none', // 마지막 항목은 선을 그리지 않음
                paddingBottom: '10px', // 선과 텍스트 간의 간격 조정
                marginBottom: index !== mbtiData.list[1].list.length - 1 ? '0px' : '-10px' 
              }}
            >
              <Typography variant="body1" style={{ fontWeight: '700', fontSize: '20px' }}>
              {emojiMap[item.name] || '🔍'} {item.name} {/* 이모티콘 추가 */}
            </Typography>
              {/* <Typography variant="body1" style={{ fontWeight: '700', fontSize: '20px' }}>
                {item.name}
              </Typography> */}
              <Typography variant="body1" style={{ fontSize: '17px' }}>
              <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{item.count}</span> {/* 글씨 키우기 */}
                {mbtiData.list[1].suffix}
              </Typography>
            </CardContent>
          ))}
        </Card>


      </Box>
    </ThemeProvider>
  );
};

export default Food;
