import React from "react";
import { Route, Routes, useNavigate, useLocation, NavigateFunction } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SpendingGoalMain from "./SpendingGoalMain";
import SpendingGoalResult from "./SpendingGoalResult";

interface GoalHeaderProps {
  navigate: NavigateFunction;
}

function GoalHeader({ navigate }: GoalHeaderProps) {
  const location = useLocation();

  const handleClose = () => {
    navigate('/Home', { replace: true });
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#f0f0f0',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000
    }}>
      <div style={{ width: '24px' }}></div>
      <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>소비 목표 세우기</div>
      <IconButton onClick={handleClose} size="small">
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

interface GoalLayoutProps {
  children: React.ReactNode;
}

function GoalLayout({ children }: GoalLayoutProps) {
  const navigate = useNavigate();

  return (
    <div style={{ paddingTop: '50px' }}>
      <GoalHeader navigate={navigate} />
      {children}
    </div>
  );
};

function SpendingGoal() {
  const navigate = useNavigate();

  return (
    <GoalLayout>
      <Routes>
        <Route path="/" element={<SpendingGoalMain />} />
        <Route path="/spendingGoalResult" element={<SpendingGoalResult onResultDone={() => navigate('/Home', { replace: true })}/>} />
      </Routes>
    </GoalLayout>
  );
};

export default SpendingGoal;
