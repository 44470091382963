import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FlutterDashIcon from '@mui/icons-material/FlutterDash';
import InsightsIcon from '@mui/icons-material/Insights';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledBottomNavigation = styled(BottomNavigation)({
  backgroundColor: '#ffffff',
  height: '70px',
  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
});

const StyledBottomNavigationAction = styled(BottomNavigationAction)<{ selected: boolean }>(({ selected }) => ({
  color: selected ? '#000000' : '#8E8E93',
  borderRadius: '12px',
  margin: '6px',
  minWidth: '60px',
  transition: 'all 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  '& .MuiBottomNavigationAction-label': {
    fontSize: '0.70rem',
    transition: 'font-size 0.2s, opacity 0.2s, font-weight 0.2s',
    opacity: selected ? 1 : 0.7,
    fontWeight: selected ? 700 : 400,
  },
}));

const AnimatedIcon = styled('div')<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s',
  '& .MuiSvgIcon-root': {
    fontSize: '24px',
    transition: 'transform 0.3s',
    transform: selected ? 'scale(1.1)' : 'scale(1)',
    color: selected ? '#000000' : '#8E8E93',
  },
}));

const BottomNav: React.FC = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleNavigation = (newValue: number) => {
    setValue(newValue);
    const routes = ['/Home', '/Mission', '/AiCoach', '/Analysis', '/MyPage'];
    navigate(routes[newValue]);
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={0}>
      <StyledBottomNavigation
        value={value}
        onChange={(_event, newValue) => handleNavigation(newValue)}
      >
        <StyledBottomNavigationAction 
          selected={value === 0}
          label="홈" 
          icon={<AnimatedIcon selected={value === 0}><HomeIcon /></AnimatedIcon>}
        />
        <StyledBottomNavigationAction 
          selected={value === 1}
          label="미션" 
          icon={<AnimatedIcon selected={value === 1}><FormatListBulletedIcon /></AnimatedIcon>}
        />
        <StyledBottomNavigationAction 
          selected={value === 2}
          label="코치" 
          icon={<AnimatedIcon selected={value === 2}><FlutterDashIcon /></AnimatedIcon>}
        />
        <StyledBottomNavigationAction 
          selected={value === 3}
          label="MBTI"
          icon={<AnimatedIcon selected={value === 3}><InsightsIcon /></AnimatedIcon>}
        />
        <StyledBottomNavigationAction 
          selected={value === 4}
          label="내정보" 
          icon={<AnimatedIcon selected={value === 4}><AccountCircleIcon /></AnimatedIcon>}
        />
      </StyledBottomNavigation>
    </Paper>
  );
};

export default BottomNav;
