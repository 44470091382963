import React from 'react';

export type ResponseType = 'dm' | 'ac' | 'ft';

export interface DMResponse {
  type: 'dm';
  prev_prob: number;
  next_prob: number;
}

export interface ACResponse {
  type: 'ac';
  availableAmount: number;
  categoryBalance: number;
}

export interface FTResponse {
  type: 'ft';
  content: string;
}

export type AIResponse = DMResponse | ACResponse | FTResponse;

export const DMResponseComponent: React.FC<{ prev_prob: number; next_prob: number }> = ({ prev_prob, next_prob }) => {
  const evalResult = evaluateProbability(next_prob);
  
  return (
    <div className={`border ${evalResult.colorClass} rounded-lg overflow-hidden`}>
      <div className={`${evalResult.colorClass} ${evalResult.textColorClass} p-3 text-center`}>
      <p className="font-bold text-2xl">{evalResult.message}</p>
      </div>
      <div className="bg-white p-4">
        <p className="text-lg font-semibold mb-3 text-black-700">{evalResult.submessage}</p>
        <p className="text-md text-gray-700">
          지출 시 성공확률 변화 :
        </p>
        <p className="text-md font-bold mt-2">
          <span className="px-2 py-1 rounded">
            {prev_prob.toFixed(0)}%
          </span>
          {' '} → {' '}
          <span className={`${evalResult.colorClass} px-2 py-1 rounded`}>
            {next_prob.toFixed(0)}%
          </span>
        </p>
      </div>
    </div>
  );
};

interface ACResponseProps {
  availableAmount: number;
  categoryBalance: number;
}

export const ACResponseComponent: React.FC<ACResponseProps> = ({ availableAmount, categoryBalance }) => {
  return (
    <div className="border border-gray-500 rounded-lg overflow-hidden">
      <div className="bg-gray-500 text-white p-3 text-center">
        <p className="font-bold text-xl">이 정도 쓸 수 있습니다!</p>
      </div>
      <div className="bg-white p-4">
        <div className="mb-3">
          <p className="text-lg text-gray-700">사용 가능 금액:</p>
          <p className="text-2xl font-bold text-blue-600">
            {availableAmount.toLocaleString()}원
          </p>
        </div>
        <div>
          <p className="text-lg text-gray-700">식비 목표 잔액:</p>
          <p className="text-2xl font-bold text-green-600">
            {categoryBalance.toLocaleString()}원
          </p>
        </div>
      </div>
    </div>
  );
};

export interface FTResponseProps {
  content: string;
}

export const FTResponseComponent: React.FC<FTResponseProps> = ({ content }) => {
  return (
    <div className="bg-white border border-gray-300 rounded-lg p-4 shadow-sm">
      <p className="text-lg text-gray-800" style={{ lineHeight: '1.6' }}>
        {content}
      </p>
    </div>
  );
};

export interface EvaluationResult {
  message: string;
  colorClass: string;
  submessage: string;
  textColorClass: string;
}

export function evaluateProbability(probability: number): EvaluationResult {
  if (probability >= 80 && probability <= 100) {
    return {
      message: '좋습니다!',
      submessage: '소비해도 좋습니다.',
      colorClass: 'bg-green-500',
      textColorClass: 'text-white'
    };
  } else if (probability >= 60 && probability < 80) {
    return {
      message: '괜찮습니다!',
      submessage: '신중히 고려하여 소비하세요.',
      colorClass: 'bg-yellow-500',
      textColorClass: 'text-white'
    };
  } else if (probability >= 40 && probability < 60) {
    return {
      message: '안됩니다!',
      submessage: '소비하지 않는 것을 권장합니다.',
      colorClass: 'bg-red-300',
      textColorClass: 'text-white'
    };
  } else if (probability < 40) {
    return {
      message: '절대 안됩니다!',
      submessage: '소비하지 않는 것을 강력히 권장합니다.',
      colorClass: 'bg-red-600',
      textColorClass: 'text-white'
    };
  } else {
    // 범위를 벗어난 경우의 기본 반환값
    return {
      message: '유효하지 않은 확률',
      submessage: '유효하지 않습니다.',
      colorClass: 'bg-gray-500',
      textColorClass: 'text-white'
    };
  }
}