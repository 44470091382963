import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { BarChart as BarChartComponent } from '@mui/x-charts'; // 비교 바 차트 컴포넌트 추가
import { useLocation } from 'react-router-dom';
import { getMbtiDetail } from '../../../api/get';
import '../../../style/chart.css';
import '../../../style/box.css';
import CurvedBackgroundTypography from '../CurvedBackgroundTypography';

// Types
interface CompareBarChartData {
  chartType: 'COMPARE_BAR_CHART';
  title: string;
  maxValue: number;
  totalAverage: number;
  myValue: number;
}

interface BarChartData {
  chartType: 'BAR_CHART';
  title: string;
  count: number;
  maxValue: number;
  list: {
    name: string;
    amount: number;
  }[];
}

interface CountListData {
  chartType: 'COUNT_LIST';
  title: string;
  count: number;
  suffix: string;
  list: {
    name: string;
    count: number;
  }[];
}

type ConsumptionData = {
  typeName: string;
  list: (CompareBarChartData | BarChartData | CountListData)[];
};

const theme = createTheme();

const cardStyles = {
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const CompareBarChart: React.FC<CompareBarChartData> = ({ title, maxValue, totalAverage, myValue }) => (
  <Card sx={{ ...cardStyles, width: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CardContent>
      <Typography variant="h6" style={{fontWeight:'700',fontSize:'20px'}}
        align="center" gutterBottom>{title}</Typography>
      <BarChartComponent
        width={350}
        height={300}
        series={[{
          data: [myValue, totalAverage], // 수치 배열로 수정
          color: '#C49FF4',
        }]}
        xAxis={[{ scaleType: 'band', data: ['내 소비', '전체 평균'] }]} // 범주를 x축으로 설정
        yAxis={[{ max: maxValue }]}
        margin={{ left: 80, right: 20 }}
      />
    </CardContent>
  </Card>
);


const BarChart: React.FC<BarChartData> = ({ title, list, maxValue }) => (
  <Card sx={{ width: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CardContent>
    <Typography variant="h6" style={{fontWeight:'700',fontSize:'20px'}}
align="center" gutterBottom>{title}</Typography>
      <BarChartComponent
        width={350}
        height={300}
        series={[{
          data: list.map(item => item.amount),
          color: '#F69292',
        }]}
        xAxis={[{ scaleType: 'band', data: list.map(item => item.name) }]}
        yAxis={[{ max: maxValue }]}
        margin={{ left: 80, right: 20 }}
      />
    </CardContent>
  </Card>
);


const CountList: React.FC<CountListData> = ({ title, suffix, list }) => (
  <Card 
    sx={{ 
      ...cardStyles,
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      width: '100%',
    }}
  >
    <CardContent 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', // 수직 정렬
        justifyContent: 'center', // 수직으로 중앙 정렬
        alignItems: 'center', // 수평으로 중앙 정렬
        width: '100%',
        boxShadow:'none'
      }}
    >
      <Typography 
        variant="h6" 
        style={{ fontWeight: '700', fontSize: '20px' }}
        align="center" 
        gutterBottom
      >
        {title}
      </Typography>
      <List 
        style={{
          backgroundColor: '#DECEF3',
          borderRadius: '10px',
          padding: '20px',
          width: '100%', 
          maxWidth: '700px', 
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          marginTop:'10px'
        }}
      >
        {list.map((item, index) => (
          <ListItem 
            key={index}
            style={{ 
              // 리스트의 마지막 항목이 아닌 경우에만 borderBottom을 추가
              borderBottom: index !== list.length - 1 ? '1px solid #C49FF4' : 'none'
            }} 
            divider
          >
            <ListItemText primary={item.name} 
              primaryTypographyProps={{ 
                fontSize: '18px', // 원하는 폰트 크기로 변경
                fontWeight: 'bold', // 폰트 굵기를 설정하려면 추가
              }}/>
            <Typography variant="body2">
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {item.count.toLocaleString()} {/* 3자리마다 쉼표 추가 */}
            </span>
              {suffix}
            </Typography>
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
);

const Hobby: React.FC = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const consumptionDetailId = data?.consumptionDetailId;
  const consumptionTypeId = data?.consumptionTypeId;
  const { name } = data?.item || {};

  const [consumptionData, setConsumptionData] = useState<ConsumptionData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMbtiDetail(consumptionTypeId, consumptionDetailId);
        setConsumptionData(response);
      } catch (error) {
        console.error('소비 데이터 로드 실패:', error);
      }
    };

    fetchData();
  }, [consumptionDetailId, consumptionTypeId]);

  if (!consumptionData) {
    return <Typography>로딩 중...</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', padding: 0 }}>
        {/* <Typography variant="h5" gutterBottom>
          "{name}"
        </Typography> */}
        <CurvedBackgroundTypography 
          text={consumptionData.typeName}
          text2={name}
          backgroundImageUrl="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fxz2o9%2FbtsJW4kj4X6%2FeFOaFVWj8aM9GOByGCYvWK%2Fimg.png"
          iconUrl="https://ifh.cc/g/oAmLtC.png"
        />
        {consumptionData.list.map((item, index) => (
          <Box key={index} mb={4}>
            {item.chartType === 'COMPARE_BAR_CHART' && (
              <CompareBarChart {...item as CompareBarChartData} />
            )}
            {item.chartType === 'BAR_CHART' && (
              <BarChart {...item as BarChartData} />
            )}
            {item.chartType === 'COUNT_LIST' && (
              <CountList {...(item as CountListData)} />
            )}
          </Box>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default Hobby;
