import React, { useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getMbtiDetail } from '../../../api/get';
import '../../../style/chart.css';
import '../../../style/box.css';
import { CircularProgress } from '@mui/material';
import CurvedBackgroundTypography from '../CurvedBackgroundTypography';

// Types
interface ListItem {
  name: string;
  percent?: number;
  count?: number;
  imgUrl?: string;
  description?: string;
}

interface ChartData {
  chartType: 'PIE_CHART' | 'COUNT_LIST' | 'CARD_LIST';
  title: string;
  count: number;
  suffix?: string;
  list: ListItem[];
}

interface MbtiData {
  typeName: string;
  list: ChartData[];
}

const theme = createTheme();

const Transport: React.FC = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const consumptionDetailId = data?.consumptionDetailId;
  const consumptionTypeId = data?.consumptionTypeId;
  const { name } = data?.item || {};

  const [mbtiData, setMbtiData] = useState<MbtiData | null>(null);

  useEffect(() => {
    const fetchMbtiData = async () => {
      try {
        const response = await getMbtiDetail(consumptionTypeId, consumptionDetailId);
        setMbtiData(response);
      } catch (error) {
        console.error('Failed to fetch MBTI data:', error);
      }
    };

    fetchMbtiData();
  }, [consumptionDetailId, consumptionTypeId]);

  if (!mbtiData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const pieChartData = mbtiData.list[0].list.map((item: ListItem) => ({
    id: item.name,
    value: item.percent || 0,
    label: `${item.name} (${item.percent}%)`,
  }));

  const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

  return (
    <ThemeProvider theme={theme}>
      <CurvedBackgroundTypography 
        text={mbtiData.typeName}
        text2={name}
        backgroundImageUrl="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdRbRhC%2FbtsJXvIJtCw%2FVqxSUqDuI5etq4IPGWXkj0%2Fimg.png"
        iconUrl="https://ifh.cc/g/CDar15.png"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px',padding: '0 20px', backgroundColor: 'white' }}>
        {/* Pie Chart Section */}
        <Card sx={{ width: '100%', maxWidth: '700px', padding: '20px',boxShadow: 'none' }}> 
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ width: '60%', display: 'flex', justifyContent: 'center',marginLeft: '10px'
          ,padding: 0,margin:0
           }}>
              <PieChart
                series={[
                  {
                    data: pieChartData,
                    innerRadius: 30,
                    outerRadius: 70,
                    paddingAngle: 2,
                    cornerRadius: 5,
                    startAngle: -90,
                  },
                ]}
                width={300}
                height={300}
                colors={colors}
                slotProps={{
                  legend: { hidden: true },
                }}
              />
            </Box>
            {/* Pie Chart Labels */}
            <Box 
              sx={{ 
                width: '50%', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'flex-start',
                margin: 0,
                padding: 0
              }}
            >
              {pieChartData.map((item, index) => (
                <Box 
                  key={item.id} 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    margin: '3px 0', 
                    maxWidth: '80%' // 레이블이 박스의 크기를 넘지 않도록 설정
                  }}
                >
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: colors[index % colors.length],
                      marginRight: '10px',
                    }}
                  />
                  <Typography 
                    variant="body2"
                    sx={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '100%' }} // 줄바꿈이 가능하게 설정
                  >
                    {item.label}
                  </Typography>
                </Box>
              ))}
            </Box>

          </Box>
        </Card>

        {/* Chart Title */}
        <Typography variant="h6" sx={{ margin: '20px 0', textAlign: 'center', fontWeight: 'bold' }}>
          {mbtiData.list[1].title}
        </Typography>

        {/* Count List Section */}
        <Card
          sx={{
            width: '100%',
            maxWidth: '700px',
            marginBottom: '30px',
            backgroundColor: '#E7F0FF',
            padding: '15px',
            borderRadius: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          {mbtiData.list[1].list.map((item: ListItem, index: number) => (
            <CardContent
              key={item.name}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: index !== mbtiData.list[1].list.length - 1 ? '1px solid #B8D8FD' : 'none',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
            >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: '700',
                    fontSize: '15px',
                    maxWidth: '150px', // 원하는 최대 너비 설정
                    overflow: 'hidden', // 내용이 넘칠 경우 숨김 처리
                    textOverflow: 'ellipsis', // 넘치는 부분에 ... 처리
                    whiteSpace: 'normal', // 텍스트 줄바꿈 허용
                  }}
                >
                  {item.name}
                </Typography>
              <Typography variant="body2" sx={{ fontSize: '15px' }}>
                <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {item.count?.toLocaleString()} 
              </span>
                {mbtiData.list[1].suffix}
              </Typography>
            </CardContent>
          ))}
        </Card>
        </Box>

        
        {/* Card List Section */}
        {mbtiData.list[2] && mbtiData.list[2].chartType === 'CARD_LIST' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px', padding: '0 20px', backgroundColor: 'white' }} >
              <Typography variant="body1" style={{ fontWeight: '700', fontSize: '20px', textAlign: 'center', margin: '20px' }}>
                  {mbtiData.list[2].title}
                </Typography>
            {mbtiData.list[2].list.map((item, index) => (
              <Card 
              sx={{
                width: '100%',
                maxWidth: '700px',
                marginBottom: '30px',
                backgroundColor: '#E7F0FF',
                padding: '15px',
                borderRadius: '10px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                textAlign: 'center',
                display: 'flex', // 중앙 정렬을 위해 flex 사용
                flexDirection: 'column', // 세로 정렬
                alignItems: 'center' // 가로 중앙 정렬
              }}>
              
              <img 
                src={item.imgUrl} 
                alt={item.name} 
                style={{ 
                  width: 300, 
                  height: 200, 
                  borderRadius: '5%',
                  marginBottom: '10px' // 이미지와 텍스트 사이 간격
                }} 
              />
              
              <Typography 
                variant="body2" 
                color="text.secondary" 
                style={{ fontWeight: '700' }}
              >
                {item.description}
              </Typography>

            </Card>

            ))}
          </Box>
        )}

      
    </ThemeProvider>
  );
};

export default Transport;
