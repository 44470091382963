import { Button, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountOverview from "./components/AccountOverview";
import SpendingPartItem from "./components/SpendingPartItem";
import { useEffect, useState } from "react";
import ExpenseGoalAlert from "../SpendingGoal/components/ExpenseGoalAlert";
import { spendGoalApi, SpendingGoalItem } from "../../api/spendGoalApi";
import { bankingApi } from "../../api/bankingApi";

function HomeMain() {
  const navigate = useNavigate();
  const [spendGoals, setSpendGoals] = useState<SpendingGoalItem[]>([]);
  const [accountBalance, setAccountBalance] = useState(0);
  const [isGoalDetermined, setIsGoalDetermined] = useState(false);

  const FetchGoals = async () => {
    try {
      const userSpendGoals = await spendGoalApi.fetchUserConsumptionGoals();

      if(userSpendGoals != null) {
        setSpendGoals(userSpendGoals.list);
        setIsGoalDetermined(true);
      }

    } catch (error) {
      console.error('계좌 정보를 불러오는 데 실패했습니다:', error);
    } finally {

    }
  };

  const FetchAccountBalance = async () => {
    try {
      const userAccountBalance = await bankingApi.getAccountBalance();

      if(userAccountBalance != null) {
        setAccountBalance(userAccountBalance.balance);
      }

    } catch (error) {
      console.error('계좌 잔액을 불러오는 데 실패했습니다:', error);
    } finally {

    }
  };

  useEffect(() => {
    FetchAccountBalance();
    FetchGoals();
  }, []);

  return (
    <div style={{ background: '#' }}>
      <Paper elevation={1} sx={{ margin: '15px', padding: '20px', borderRadius: '10px' }}>
        <Typography variant="h6" gutterBottom color="text.secondary">
          사용자 님의 소비 통장
        </Typography>
        <AccountOverview
          onNavigate={() => navigate('accountDetail')}
          balance={accountBalance}
          onDismiss={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
        
        {/* 여기에 마진을 추가하여 간격을 줍니다 */}
        <Typography variant="h6" sx={{ mt: 4 }} color="text.secondary">
          소비 목표
        </Typography>
        
        {isGoalDetermined ? (
          <Stack direction="column">
            {spendGoals.map((goal, index) => (
              <SpendingPartItem
                key={index}
                categoryName={goal.consumptionType}
                spendingMoney={goal.spentAmount}
                targetMoney={goal.targetAmount}
              />
            ))}
          </Stack>
        ) : (
          <ExpenseGoalAlert />
        )}
      </Paper>
    </div>
  );
};

export default HomeMain;
