import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Container, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import zozalLogo from '../../assets/zozal_logo.png';
import { colors } from '../../styles/colors';
import { checkVerificationCodeQueryParams, sendVerificationCodeQueryParams, signUpRequest } from '../../api/authApi';
import { authApi } from './../../api/authApi';
import SignUpStep1 from './subScreen/SignUpScreen1';
import SignUpStep2 from './subScreen/SignUpScreen2';
import SignUpStep3 from './subScreen/SignUpScreen3';
import SignUpStep4 from './subScreen/SignUpScreen4';

const AuthContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});

const AuthButton = styled(Button)({
  marginTop: '20px',
  width: '100%',
  padding: '10px',
  fontSize: '16px',
  backgroundColor: colors.zozal_green,
  color: 'white',
  '&:hover': {
    backgroundColor: colors.zozal_grey,
  },
});

type AuthScreenProps = {
  trySignIn: (id: string, password: string) => void;
};

function AuthScreen({ trySignIn }: AuthScreenProps) {
  const [isLogin, setIsLogin] = useState(true);
  const [signUpStep, setSignUpStep] = useState(1);
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState({
    name: '',
    gender: 'male',
    age: 0,
    location: '',
    job: '',
    income: '',
    password: '',
    confirmPassword: ''
  });
  const [loginData, setLoginData] = useState({
    id: '',
    loginPassword: '',
  });

  const toggleAuthMode = () => {
    setIsLogin(!isLogin);
    setSignUpStep(1);
    setEmail('');
    setUserData({
      name: '',
      gender: '남성',
      age: 0,
      location: '',
      job: '',
      income: '',
      password: '',
      confirmPassword: ''
    });
  };

  const handleSignIn = (e: React.FormEvent) => {
    e.preventDefault();
    trySignIn(loginData.id, loginData.loginPassword);
  };

  const handleNextStep = () => {
    setSignUpStep(prevStep => prevStep + 1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();

    const signUpBodyParams: signUpRequest = {
      name: userData.name,
      gender: userData.gender,
      email: email,
      password: userData.password,
      age: userData.age,
      job: userData.job,
      salary: userData.income,
      region: userData.location,
    };
    try {
      await authApi.signUp(signUpBodyParams);
      setIsLogin(true);
    } catch (error) {
      console.error('Failed to send verification code:', error);
    }

    console.log('Sign up with:', { email, ...userData });
  };

  return (
    <AuthContainer maxWidth="xs">
      <motion.div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
          maxWidth: '80%',
          width: '100%',
        }}
      >
        <img
          src={zozalLogo}
          alt="Zozal Logo"
          style={{ height: '60px', marginRight: '15px' }}
        />
        <h1 style={{
          fontSize: '48px',
          fontWeight: 'bold',
          margin: 0,
          height: '60px',
          lineHeight: '60px',
          color: '#6E6E6E'
        }}>
          <span className="text-gray-500 text-xxl font-bold">아낌나무</span>
        </h1>
      </motion.div>
      <Box component="form" sx={{ width: '100%' }}>
        {isLogin ? (
          <>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              name="id"
              value={loginData.id}
              onChange={handleChange}
            />
            <TextField
              label="비밀번호"
              variant="outlined"
              fullWidth
              name="loginPassword"
              margin="normal"
              type="password"
              value={loginData.loginPassword}
              onChange={handleChange}
              autoComplete="current-password"
            />
            <AuthButton type="submit" onClick={handleSignIn}>
              <span className="white text-xl font-regular white">로그인</span>
            </AuthButton>
          </>
        ) : (
          <>
            {signUpStep === 1 && (
              <SignUpStep1
                email={email}
                setEmail={setEmail}
                onNextStep={handleNextStep}
              />
            )}
            {signUpStep === 2 && (
              <SignUpStep2
                userData={userData}
                setUserData={setUserData}
                onNextStep={handleNextStep}
              />
            )}
            {signUpStep === 3 && (
              <SignUpStep3
                userData={userData}
                setUserData={setUserData}
                onNextStep={handleNextStep}
              />
            )}
            {signUpStep === 4 && (
              <SignUpStep4
                userData={userData}
                setUserData={setUserData}
                onSignUp={handleSignUp}
              />
            )}
          </>
        )}
      </Box>
      <Divider sx={{ width: '100%', margin: '20px 0' }} />
      <Button onClick={toggleAuthMode} sx={{ textTransform: 'none', color: colors.zozal_green }}>
        <span style={{ color: colors.zozal_green }} className="ml-4 text-l font-semibold">
          {isLogin ? "Don't have an account? Sign up" : 'Already have an account? Login'}
        </span>
      </Button>
    </AuthContainer>
  );
}

export default AuthScreen;
