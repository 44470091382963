import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useLocation } from 'react-router-dom';
import { getMbtiDetail } from '../../../api/get';
import { CircularProgress } from '@mui/material';
import CurvedBackgroundTypography from '../CurvedBackgroundTypography';

// Types
interface CompareBarChartData {
  chartType: 'COMPARE_BAR_CHART';
  title: string;
  maxValue: number;
  totalAverage: number;
  myValue: number;
}

interface BarChartData {
  chartType: 'BAR_CHART';
  title: string;
  count: number;
  maxValue: number;
  list: {
    name: string;
    amount: number;
  }[];
}

interface BarChartMyData {
  chartType: 'BAR_CHART_MY';
  title: string;
  count: number;
  maxValue: number;
  list: {
    name: string;
    amount: number;
    myType: boolean;
  }[];
}

interface TransportData {
  typeName: string;
  list: (CompareBarChartData | BarChartData | BarChartMyData)[];
}

const theme = createTheme();

const cardStyles = {
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const CompareBarChart: React.FC<CompareBarChartData> = ({ title, maxValue, totalAverage, myValue }) => (
  <Card sx={{ ...cardStyles,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CardContent>
    <Typography variant="h6" style={{fontWeight:'700',fontSize:'20px'}}
        align="center" gutterBottom>{title}</Typography>
      <BarChart
        width={350}
        height={300}
        series={[
          { data: [myValue], label: '나의 비용', color: '#2196f3' },
          { data: [totalAverage], label: '평균 비용', color: '#4caf50' },
        ]}
        xAxis={[{ scaleType: 'band', data: ['비용 비교'] }]}
        yAxis={[{ min: 0, max: maxValue }]}
        margin={{ left: 80, right: 20 }}
      />
    </CardContent>
  </Card>
);

const BarChartComponent: React.FC<BarChartData | BarChartMyData> = ({ title, list, maxValue }) => (
  <Card sx={{ ...cardStyles,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CardContent>
    <Typography variant="h6" style={{fontWeight:'700',fontSize:'20px',}}
        align="center" gutterBottom>{title}</Typography>
      <BarChart
        width={350}
        height={300}
        series={[
          {
            data: list.map(item => item.amount),
            color: '#2196f3',
          },
        ]}
        xAxis={[{ scaleType: 'band', data: list.map(item => item.name) }]}
        yAxis={[{ max: maxValue }]}
      />
    </CardContent>
  </Card>
);

const Transport: React.FC = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const consumptionDetailId = data?.consumptionDetailId;
  const consumptionTypeId = data?.consumptionTypeId;
  const { name } = data?.item || {};

  const [transportData, setTransportData] = useState<TransportData | null>(null);

  useEffect(() => {
    const fetchTransportData = async () => {
      try {
        const response = await getMbtiDetail(consumptionTypeId, consumptionDetailId);
        setTransportData(response);
      } catch (error) {
        console.error('Failed to fetch transport data:', error);
      }
    };

    fetchTransportData();
  }, [consumptionDetailId, consumptionTypeId]);

  if (!transportData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', padding: 0 }}>
        {/* <Typography variant="h5" gutterBottom>
          "{name}"
        </Typography> */}
        <CurvedBackgroundTypography 
          text={transportData.typeName}
          text2={name}
          backgroundImageUrl="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdRbRhC%2FbtsJXvIJtCw%2FVqxSUqDuI5etq4IPGWXkj0%2Fimg.png"
          iconUrl="https://ifh.cc/g/QL5LMz.png"
        />
        {transportData.list.map((item, index) => (
          <Box key={index} mb={4}>
            {item.chartType === 'COMPARE_BAR_CHART' && (
              <CompareBarChart {...item as CompareBarChartData} />
            )}
            {(item.chartType === 'BAR_CHART' || item.chartType === 'BAR_CHART_MY') && (
              <BarChartComponent {...item as BarChartData | BarChartMyData} />
            )}
          </Box>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default Transport;