import axiosInstance from "../utils/axiosInstance";

export const bankingApi = {
  getAccountBalance: async (): Promise<AccountBalanceResponse | null> => {
    try {
      const response = await axiosInstance.get<AccountBalanceResponse>('/banking/inquireDemandDepositAccountBalance');
      
      if(response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  },

  getTransactionList: async (): Promise<TransactionItem[] | null> => {
    const queryParams: GetTransActionsRequest = {
      startDate: "2024-10-01",
      endDate: "2024-10-30",
      orderByType: "DESC"
    }

    try {
      const response = await axiosInstance.get<TransactionItem[]>('/banking/inquireTransactionHistoryNew', {
        params: queryParams
      });
      
      if(response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  },

  getBestTransActionTop3: async (): Promise<TransactionItem[] | null> => {
    const queryParams: GetTransActionsRequest = {
      startDate: "2024-10-01",
      endDate: "2024-10-30",
      orderByType: "DESC"
    }

    try {
      const response = await axiosInstance.get<TransactionItem[]>('/banking/inquireTransactionHistoryNew', {
        params: queryParams
      });
      
      if(response.status === 200) {
        return response.data.sort((a, b) => {
          const contribA = a.successContribution;
          const contribB = b.successContribution;
          
          return contribB - contribA;
        })
        .slice(0, 3);
      } else {
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  },

  getWorstTransActionTop3: async (): Promise<TransactionItem[] | null> => {
    const queryParams: GetTransActionsRequest = {
      startDate: "2024-10-01",
      endDate: "2024-10-30",
      orderByType: "DESC"
    }

    try {
      const response = await axiosInstance.get<TransactionItem[]>('/banking/inquireTransactionHistoryNew', {
        params: queryParams
      });
      
      if(response.status === 200) {
        return response.data.sort((a, b) => {
          const contribA = a.successContribution;
          const contribB = b.successContribution;
          
          return contribA - contribB;
        })
        .slice(0, 3);
      } else {
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  }
};

interface AccountBalanceResponse {
  balance: number;
  accountNo: string;
}


export interface TransactionItem {
  id: number;
  type: string;
  userId: number;
  description: string;
  amount: number;
  subCategory: string;
  consumptionTime: string;
  accountNo: string;
  successContribution: number;
  successProbability: number;
}

interface TransactionApiResponse {
  totalCount: string;
  list: TransactionItem[];
}

export interface GetTransActionsRequest {
  startDate: string;
  endDate: string;
  orderByType: string;
};
