import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BankAccount, setUserAccountParams, userApi } from "../../api/userApi";
import { CircularProgress } from "@mui/material";
import BasicButton from "../../components/BasicButton";
import { Check } from "lucide-react";

type AccountRegisterScreenProps = {
  onAccountSelected: () => void;
};

function ChangeAccountPage({ onAccountSelected }: AccountRegisterScreenProps) {
  const [accounts, setAccounts] = useState<BankAccount[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    setIsLoading(true);
    try {
      const userAccounts = await userApi.fetchUserAccounts();
      setAccounts(userAccounts);
    } catch (error) {
      console.error('계좌 정보를 불러오는 데 실패했습니다:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccountSelect = (accountId: string) => {
    setSelectedAccountId(accountId);
  };

  const handleRegister = async () => {
    console.log('선택된 계좌 ID:', selectedAccountId);
    try {
      const userAccountNo: setUserAccountParams = {
        accountNo: selectedAccountId!
      };
      const registerResult = await userApi.setUserAccount(userAccountNo);
      if (registerResult) {
        console.log('소비 계좌등록 성공');
        setShowModal(true);
      } else {
        console.log('소비 계좌등록 실패');
      }
    } catch (error) {
      console.error('소비 계좌등록 요청에서 오류가 발생했습니다.', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalConfirm = () => {
    setShowModal(false);
    navigate(-1);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-[calc(100vh-40px)] w-full bg-white">
      <div className="flex-grow flex flex-col pt-6 px-4">
        <h1 className="text-2xl font-bold mb-4">소비계좌 선택</h1>
        <div className="flex-grow space-y-4 overflow-y-auto">
          {accounts.map((account) => (
            <div
              key={account.accountNo}
              className={`flex items-center p-4 rounded-lg border ${
                selectedAccountId === account.accountNo ? 'border-green-500' : 'border-gray-300'
              } cursor-pointer`}
              onClick={() => handleAccountSelect(account.accountNo)}
            >
              <img
                src={"https://via.placeholder.com/50"}
                alt={`${account.accountName} 이미지`}
                className="w-12 h-12 rounded-md mr-4"
              />
              <div className="flex-grow">
                <p className="font-semibold">{account.accountName}</p>
                <p className="text-sm text-gray-600">{account.accountNo}</p>
              </div>
              <div
                className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                  selectedAccountId === account.accountNo ? 'border-green-500 bg-green-500' : 'border-gray-300'
                }`}
              >
                {selectedAccountId === account.accountNo && <Check size={16} color="white" />}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-4">
        <BasicButton
          onClick={handleRegister}
          disabled={!selectedAccountId}
          className="w-full"
          variant="contained"
          color="primary"
        >
          <span className="white text-xl font-regular">소비계좌 변경하기</span>
        </BasicButton>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-[300px]">
            <h2 className="text-xl font-bold mb-4">소비 계좌 등록 성공</h2>
            <p className="mb-6">소비 계좌가 성공적으로 변경되었습니다.</p>
            <BasicButton
              onClick={handleModalConfirm}
              className="w-full h-10 text-sm py-1"
              variant="contained"
              color="primary"
            >
              확인
            </BasicButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangeAccountPage;