import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useLocation, useNavigate } from 'react-router-dom'; // useNavigate 추가
import { getMbtiDetail } from '../../../api/get';
import '../../../style/chart.css';
import '../../../style/box.css';
import { CircularProgress } from '@mui/material';
import CurvedBackgroundTypography from '../CurvedBackgroundTypography';

// Types
interface CompareBarChartData {
  chartType: 'COMPARE_BAR_CHART';
  title: string;
  maxValue: number;
  totalAverage: number;
  myValue: number;
}

interface BarChartData {
  chartType: 'BAR_CHART';
  title: string;
  count: number;
  maxValue: number;
  list: {
    name: string;
    amount: number;
  }[];
}

interface TransportData {
  typeName: string;
  list: (CompareBarChartData | BarChartData)[];
}

const theme = createTheme();

const cardStyles = {
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const CompareBarChart: React.FC<CompareBarChartData> = ({ title, maxValue, totalAverage, myValue }) => (
  <Card sx={{ ...cardStyles,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CardContent>
    <Typography variant="h6" style={{fontWeight:'700',fontSize:'20px'}}
        align="center" gutterBottom>{title}</Typography>
      <BarChart
        width={350}
        height={300}
        series={[
          { data: [myValue], label: '나의 비용', color: '#FFE27D' },
          { data: [totalAverage], label: '평균 비용', color: '#4caf50' },
        ]}
        xAxis={[{ scaleType: 'band', data: ['비용 비교'] }]}
        yAxis={[{ min: 0, max: maxValue }]}
        margin={{ left: 80, right: 20 }}
      />
    </CardContent>
  </Card>
);

const BarChartComponent: React.FC<BarChartData> = ({ title, list, maxValue }) => (
  <Card sx={{ ...cardStyles,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CardContent>
    <Typography variant="h6" style={{fontWeight:'700',fontSize:'20px'}}
        align="center" gutterBottom>{title}</Typography>
      <BarChart
        width={350}
        height={300}
        series={[
          {
            data: list.map(item => item.amount),
            color: '#F99575',
          },
        ]}
        xAxis={[{ scaleType: 'band', data: list.map(item => item.name) }]}
        yAxis={[{ max: maxValue }]}
        margin={{ left: 70, right: 20 }}
      />
    </CardContent>
  </Card>
);

const Cafe: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate(); // useNavigate 추가
  const { data } = location.state || {};
  const consumptionDetailId = data?.consumptionDetailId;
  const consumptionTypeId = data?.consumptionTypeId;
  const { name } = data?.item || {};

  const [cafeData, setCafeData] = useState<TransportData | null>(null);

  useEffect(() => {
    const fetchCafeData = async () => {
      try {
        const response = await getMbtiDetail(consumptionTypeId, consumptionDetailId);
        setCafeData(response);
      } catch (error) {
        console.error('Failed to fetch cafe data:', error);
      }
    };

    fetchCafeData();
  }, [consumptionDetailId, consumptionTypeId]);

  // 네모 상자를 누를 때 이동하는 함수
  const handleBoxClick = (index: number) => {
    navigate(`/Analysis/cafe/${index + 1}`); // index 기반 경로 이동
  };

  if (!cafeData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', padding: 0 }}>
      {/* <Typography variant="h5" component="h1" gutterBottom style={{fontWeight:'700',
        alignItems: 'center',display: 'flex', flexDirection: 'column'
      }}>
          {name}
        </Typography> */}
      <CurvedBackgroundTypography 
        text2={name}
        text={cafeData.typeName}
        backgroundImageUrl="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbjkYes%2FbtsJWwhkZzI%2FVeyP0f318DKlTbZQZNy8eK%2Fimg.png"
        iconUrl="https://ifh.cc/g/84Zqtl.png"
      />
      

        {cafeData.list.map((item, index) => (
          <Box key={index} mb={4}
           onClick={() => handleBoxClick(index)} 
           > {/* 클릭 이벤트 추가 */}
            {item.chartType === 'COMPARE_BAR_CHART' && (
              <CompareBarChart {...item as CompareBarChartData} />
            )}
            {item.chartType === 'BAR_CHART' && (
              <BarChartComponent {...item as BarChartData} />
            )}
          </Box>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default Cafe;
