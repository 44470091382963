import axiosInstance from '../utils/axiosInstance';

export async function main() {
  try {
    const response = await axiosInstance.get('/mbti');
    return response.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getMbtiData(consumptionTypeId: number) { 
  try {
    const response = await axiosInstance.get(`/mbti/${consumptionTypeId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getMbtiDetail(consumptionTypeId: number, consumptionDetailId: number) { 
  try {
    const response = await axiosInstance.get(`/mbti/${consumptionTypeId}/${consumptionDetailId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getMisson() { 
  try {
    const response = await axiosInstance.get(`/missions`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getLastMisson() { 
  try {
    const response = await axiosInstance.get(`/missions/lastweek`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserInfo() { 
  try {
    const response = await axiosInstance.get(`/users/searchUserByEmail`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
