import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box, CircularProgress } from '@mui/material';
import '../../../style/chart.css';
import '../../../style/box.css';
import { useLocation } from 'react-router-dom';
import { getMbtiDetail } from '../../../api/get';
import { Chart, registerables, PointElement, LineElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import CurvedBackgroundTypography from '../CurvedBackgroundTypography';

Chart.register(...registerables, PointElement, LineElement, CategoryScale, LinearScale, Tooltip, Legend);

const theme = createTheme();

interface LineGraphItem {
  name: string;
  count: number;
}

interface SimpleItem {
  chartType: string;
  title: string;
  count: number;
}

interface MbtiData {
  typeName: string;
  list: {
    chartType: string;
    title: string;
    count: number;
    maxValue?: number;
    list: LineGraphItem[];
  }[];
}

const Food: React.FC = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const consumptionDetailId = data?.consumptionDetailId;
  const { name } = data.item;
  const consumptionTypeId = data?.consumptionTypeId;

  const [mbtiData, setMbtiData] = useState<MbtiData | null>(null);

  useEffect(() => {
    const fetchMbtiData = async () => {
      try {
        const response = await getMbtiDetail(consumptionTypeId, consumptionDetailId);
        setMbtiData(response);
      } catch (error) {
        console.error('Error fetching MBTI data:', error);
      }
    };

    fetchMbtiData();
  }, [consumptionDetailId]);

  if (!mbtiData) {
    return <CircularProgress />;
  }

  const lineGraphData = mbtiData.list.find(item => item.chartType === 'LINE_GRAPH')?.list as LineGraphItem[];
  const simpleData = mbtiData.list.find(item => item.chartType === 'SIMPLE') as SimpleItem;

  const chartData = {
    labels: lineGraphData?.map(item => item.name),
    datasets: [
      {
        label: '식사 시간대',
        data: lineGraphData?.map(item => item.count),
        borderColor: '#36A2EB',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        fill: true,
      },
    ],
  };

  return (
    <ThemeProvider theme={theme}>
       {/* <Typography variant="h5" component="h1" gutterBottom style={{fontWeight:'700',
        alignItems: 'center',display: 'flex', flexDirection: 'column'
      }}>
          {name}
        </Typography> */}

        <CurvedBackgroundTypography 
          text={mbtiData.typeName}
          text2={name}
          backgroundImageUrl="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbRDrBk%2FbtsJXtD6dBc%2FCXJs06VpbZyR7q8AwtenB1%2Fimg.png"
          iconUrl="https://ifh.cc/g/9c4X8B.png"
        />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '30px', padding: '30px',
        backgroundColor:'white'
       }}>

        <Card sx={{ width: '100%', maxWidth: '700px', marginBottom: '20px', padding: '20px',boxShadow: 'none' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" align="center" sx={{ marginBottom: '20px' }}>
              {simpleData?.title}: <strong style={{fontSize:'23px'}}>{simpleData?.count.toFixed(2)} 🍚</strong>
            </Typography>
            <Box sx={{ width: '100%', height: '300px' }}>
              <Line
               data={{
                  ...chartData,
                  datasets: chartData.datasets.map((dataset, index) => ({
                    ...dataset,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)', // 배경색 (채우기)
                    borderColor: 'rgba(255, 99, 132, 1)', // 선 색상
                    borderWidth: 3, // 선 굵기
                  })),
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      max: Math.max(...lineGraphData.map(item => item.count)),
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Card>

        <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'center' }}>
          위 그래프는 하루 중 식사 시간대별 횟수를 나타냅니다.
        </Typography>

        
      </Box>
    </ThemeProvider>
  );
};

export default Food;
