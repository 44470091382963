import axiosInstance from '../utils/axiosInstance';

export async function main(url: string) {
    const response = await axiosInstance.post('');
    return response.data;
  }

export async function sendVerificationCode(userEmail: String) {
  try {
    const response = await axiosInstance.post('/api/users/sendEmail', {
      withCredentials: true,
      params: {
        email: userEmail,
      }
    });
    console.log('response : ', response);
    return response.data;
  } catch (error) {
    console.log(error)
  }
}

export async function postLastMisson() { 
  try {
    const response = await axiosInstance.post(`/missions/add`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const addPoint = async (successCount: number) => {
  try {
    // console.log(successCount);
    const response = await axiosInstance.patch('/users/points/add',successCount,
      {
        headers: {
          'Content-Type': 'application/json', // 헤더에 Content-Type 추가
        },
      }
    );
    console.log(response)
    return response.data;
  } catch (error) {
    console.error('Error adding points:', error);
  }
};
