import axiosInstance from "../utils/axiosInstance";

export const payApi = {

  postPayRequest: async (body_params: postPayRequestParams): Promise<PaymentResponse | null> => {
    try {
      const response = await axiosInstance.post<PaymentResponse>('/account/payment', body_params);
      
      if (response.status === 200) {
        return response.data;
      } else {
        console.log('결제 요청 중 예외상황 발생');
        return null;
      }
    } catch (error) {
      console.error('결제 요청 중 오류 발생', error);
      return null;
    }
  },

  postConsumptionPrediction: async (body_params: postConsumptionPredictionParams): Promise<boolean> => {
    try {
      const response = await axiosInstance.post('/consumption-prediction', body_params);
      if (response.status === 200) {
        return true;
      } else {
        console.log('결제 요청 중 예외상황 발생');
        return false;
      }
    } catch (error) {
      console.error('결제 요청 중 오류 발생', error);
      return false;
    }
  }
};

export interface postPayRequestParams {
  balance: number;
  title: string;
  category: string;
};

export interface postConsumptionPredictionParams {
  id: number;
};

export interface PaymentResponse {
  balance: number;
  historyId: number;
  message: string;
  userId: number;
}
