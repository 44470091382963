import React from 'react';
import UserProfileHeader from "./components/UserProfileHeader";
import { CardContainer, CardItem } from './components/CardComponents';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ChangeAccountPage from './ChangeAccountPage';
import ChangeConsumptionGoalPage from './ChangeConsumptionGoalPage';
import PersonalInformationConsentPage from './PersonalInformationConsentPage';
import UserInfoDetailPage from './UserInfoDetailPage';
import UserInfoEditPage from './UserInfoEditPage';

function MyPageContent() {
  const navigate = useNavigate();
  const onLogout = function (): void {
    // localStorage.removeItem("token");
    // Redirect to the login page and force a page refresh
    window.location.href = '/';
    console.log("User logged out");
  }

  const goUserInfoDetail = () => {
    // console.log("Navigating with consumptionDetailId:", consumptionDetailId);
    navigate(`/MyPage/UserInfoDetail`);
  };

  const changeAccount = () => {
    // console.log("Navigating with consumptionDetailId:", consumptionDetailId);
    navigate(`/MyPage/ChangeAccount`);
  };

  const changeGoal = () => {
    // console.log("Navigating with consumptionDetailId:", consumptionDetailId);
    navigate(`/MyPage/ChangeConsumptionGoal`);
  };

  const personalInformationConsent = () => {
    // console.log("Navigating with consumptionDetailId:", consumptionDetailId);
    navigate(`/MyPage/PersonalInformationConsent`);
  };
  
  return (
    <div>
      <div className="max-w-md mx-auto space-y-4">
        <UserProfileHeader 
          name={"사용자"} 
          email={"a1@naver.com"} 
          onLogout={goUserInfoDetail}
        />
        <CardContainer>
          <CardItem title="소비 계좌 변경하기" onClick={changeAccount} />
          <CardItem title="소비 목표 변경하기" onClick={changeGoal} />
        </CardContainer>

        <CardContainer>
          <CardItem title="약관 및 개인정보 처리 동의" onClick={personalInformationConsent} />
          <CardItem title="개인정보 처리방침" onClick={personalInformationConsent} />
        </CardContainer>

        <CardContainer>
          <CardItem title="탈퇴하기" onClick={onLogout} />
        </CardContainer>

        <div>
          <p className='pt-3 px-3'>앱 버전 1.0.0</p>
        </div>
      </div>
    </div>
  );
}

function MyPage() {
  function setIsAccountRegistered(arg0: boolean): void {
    throw new Error('Function not implemented.');
  }

  return (
    <Routes>
      <Route path="/" element={<MyPageContent />} />
      <Route path="/UserInfoDetail" element={<UserInfoDetailPage />} />
      <Route path="/ChangeAccount" element={<ChangeAccountPage onAccountSelected={() => setIsAccountRegistered(true)} />} />
      <Route path="/ChangeConsumptionGoal" element={<ChangeConsumptionGoalPage />} />
      <Route path="/PersonalInformationConsent" element={<PersonalInformationConsentPage />} />
      <Route path="/EditUserInfo" element={<UserInfoEditPage />} />
    </Routes>
  );
}

export default MyPage;