import React, { useState, useEffect } from 'react';
import { Check } from 'lucide-react';
import { CircularProgress } from '@mui/material';
import { BankAccount, setUserAccountParams, userApi } from '../../api/userApi';
import BasicButton from '../../components/BasicButton';

import image1 from '../../assets/woori.png';
import image2 from '../../assets/shinhan.png';
import image3 from '../../assets/kako.png';

type AccountRegisterScreenProps = {
  onAccountSelected: () => void;
};

const images = [
  { src: image1, alt: '이미지 1' },
  { src: image2, alt: '이미지 2' },
  { src: image3, alt: '이미지 3' },
];

const lightGreenColor = 'rgba(17, 194, 176, 0.2)';

function AccountRegisterScreen({ onAccountSelected }: AccountRegisterScreenProps) {
  const [accounts, setAccounts] = useState<BankAccount[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    setIsLoading(true);
    try {
      const userAccounts = await userApi.fetchUserAccounts();
      setAccounts(userAccounts);
    } catch (error) {
      console.error('계좌 정보를 불러오는 데 실패했습니다:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccountSelect = (accountId: string) => {
    setSelectedAccountId(accountId);
  };

  const handleRegister = async () => {
    console.log('선택된 계좌 ID:', selectedAccountId);
    try {
      const userAccountNo: setUserAccountParams = {
        accountNo: selectedAccountId!
      };
      const registerResult = await userApi.setUserAccount(userAccountNo);
      if (registerResult) {
        console.log('소비 계좌등록 성공');
        onAccountSelected();
      } else {
        console.log('소비 계좌등록 실패');
      }
    } catch (error) {
      console.error('소비 계좌등록 요청에서 오류가 발생했습니다.', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-[calc(100vh-40px)] w-full bg-white">
      <div className="flex-grow flex flex-col pt-6 px-4">
        <h1 className="text-2xl font-bold mb-4">소비계좌 선택</h1>
        <div className="flex-grow space-y-4 overflow-y-auto">
          {accounts.map((account, index) => (
            <div
              key={account.accountNo}
              className={`flex items-center p-4 rounded-lg border ${
                selectedAccountId === account.accountNo ? 'border-[#11C2B0]' : 'border-gray-300'
              } cursor-pointer`}
              style={{
                backgroundColor: selectedAccountId === account.accountNo ? lightGreenColor : 'transparent'
              }}
              onClick={() => handleAccountSelect(account.accountNo)}
            >
              <img
                src={images[index].src}
                alt={`${account.accountName} 이미지`}
                className="w-12 h-12 rounded-md mr-4"
              />
              <div className="flex-grow">
                <p className="font-semibold">{account.accountName}</p>
                <p className="text-sm text-gray-600">{account.accountNo}</p>
              </div>
              <div
                className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                  selectedAccountId === account.accountNo ? 'border-[#11C2B0] bg-[#11C2B0]' : 'border-gray-300'
                }`}
              >
                {selectedAccountId === account.accountNo && <Check size={16} color="white" />}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="p-4">
        <BasicButton
          onClick={handleRegister}
          disabled={!selectedAccountId}
          className="w-full"
          variant="contained"
          color="primary"
        >
          <span className="white text-xl font-regular">등록하기</span>
        </BasicButton>
      </div>
    </div>
  );
}

export default AccountRegisterScreen;
