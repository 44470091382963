import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { bankingApi } from '../../api/bankingApi';
import bankLogoImage from '../../assets/kakao_logo.png';
import BasicButton from '../../components/BasicButton';

type PayMainScreenProps = {
  onClickQrScan: () => void;
};

const colors = {
  zozal_green: '#11C2B0',
  zozal_grey: '#A4A4A4',
};

function PayMainScreen({ onClickQrScan }: PayMainScreenProps) {
  const [accountBalance, setAccountBalance] = useState(0);
  const paymentCode = JSON.stringify({
    domainId: "https://j11d103.p.ssafy.io/",
    destinataryId: 1120030,
    destinatary: "스타벅스 구미인동점",
  });

  const FetchAccountBalance = async () => {
    try {
      const userAccountBalance = await bankingApi.getAccountBalance();
      if (userAccountBalance != null) {
        setAccountBalance(userAccountBalance.balance);
      }
    } catch (error) {
      console.error('계좌 잔액을 불러오는 데 실패했습니다:', error);
    }
  };

  useEffect(() => {
    FetchAccountBalance();
  }, []);

  return (
    <div className="flex flex-col h-[calc(100vh-115px)] bg-gray-100">
      {/* QR 코드 섹션 */}
      <div className="flex-1 flex flex-col items-center justify-center p-6">
        <div className="w-full max-w-md bg-white rounded-[10px] overflow-hidden shadow-lg">
          <div className="bg-gradient-to-br from-[#8DE3D9] via-[#C8F1EC] to-white p-8 flex items-center justify-center">
            <div className="bg-white p-4 rounded-xl shadow-inner border-2 border-[#11C2B0]">
              <QRCodeSVG value={paymentCode} size={240} />
            </div>
          </div>
          <div className="p-6">
            <h2 className="text-2xl font-bold text-center mb-2 text-gray-800">
              결제 QR 코드
            </h2>
            <p className="text-center text-gray-600 mb-4">
              QR 코드를 점원에게 보여주세요.
            </p>
          </div>
        </div>
      </div>

      {/* 잔액 표시 섹션 */}
      <div className="bg-white shadow-md p-4 mx-4 rounded-[10px] mb-2">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img
              src={bankLogoImage}
              alt="Bank Logo"
              className="w-10 h-10 rounded-full"
            />
            <span className="ml-3 text-lg font-semibold">잔액</span>
          </div>
          <span className="text-xl font-semibold text-black">
            {accountBalance.toLocaleString()}원
          </span>
        </div>
      </div>

      {/* 하단 버튼 */}
      <div className="px-4 pb-4">
        <BasicButton 
          className="w-full py-3 rounded-lg text-lg font-semibold text-white" 
          style={{ backgroundColor: colors.zozal_green }}
          onClick={onClickQrScan}
        >
          결제 코드 스캔
        </BasicButton>
      </div>
    </div>
  );
}

export default PayMainScreen;
