import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Box,
  CircularProgress,
} from '@mui/material';
import cafe from '../../assets/cafe.png';
import food from '../../assets/food.png';
import leisure from '../../assets/leisure.png';
import life from '../../assets/life.png';
import transport from '../../assets/transport.png';
import { getMisson, getLastMisson } from '../../api/get';
import { addPoint } from '../../api/post';
import LastWeekMissionsModal from './LastWeekMissionsModal';

const Mission: React.FC = () => {
  const [missionData, setMissionData] = useState<any>(null);
  const [lastWeekMissions, setLastWeekMissions] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);

  const refreshMissionData = async () => {
    const response = await getMisson();
    setMissionData(response);
    setTotalPoints(response.point); // 미션 정보에서 포인트 값 설정
  };

  const handleAddPoints = async (successCount: number) => {
    try {
      const result = await addPoint(successCount);
      if (result && typeof result.point === 'number') {
        console.log('포인트 추가 성공:', result.point); // 응답 확인
        setTotalPoints(result.point);
      } else {
        console.log(result);
      }
    } catch (error) {
      console.error('포인트 추가 오류:', error);
    }
  };

  useEffect(() => {
    const fetchMissionData = async () => {
      try {
        const response = await getMisson();
        setMissionData(response);
        setTotalPoints(response.point); // 초기 포인트 설정

        // 미션이 없을 경우 지난 주 미션 확인
        if (!response.userMissionList.length) {
          const lastWeekResponse = await getLastMisson();
          setLastWeekMissions(lastWeekResponse);
          setOpenModal(true);
        }
      } catch (error) {
        console.error('Error fetching mission data:', error);
      }
    };

    fetchMissionData();
  }, []);

  const getMissionImage = (type: string) => {
    switch (type) {
      case 'FOOD':
        return food;
      case 'CAFE_AND_SNACK':
        return cafe;
      case 'LEISURE':
        return leisure;
      case 'LIVING':
        return life;
      case 'TRANSPORTATION':
        return transport;
      default:
        return ''; 
    }
  };

  if (!missionData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          이번주 미션
        </Typography>
        <Typography variant="h6" sx={{ fontSize: '14px', textAlign: 'right' }}>
          💰 {totalPoints}
        </Typography>
        </Box>

      {missionData.userMissionList.map((mission: any, index: number) => (
        <Card
          key={mission.id}
          sx={{
            backgroundColor: '#ffffff',  
            padding: '16px',  
            paddingLeft: '24px',  
            paddingRight: '24px',  
            borderRadius: 2,  
            boxShadow: 3,  
            mb: 3,
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'translateY(-5px)',
            },
          }}
        >
          <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: 100,
                height: 80,
                mr: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={getMissionImage(mission.mission.type)}
                alt={mission.mission.type}
                style={{ width: '100px', height: '80px' }}
                
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ mb: 1, fontSize: '1rem', fontWeight: 'bold' }}>
                미션 {index + 1}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, fontSize: '0.85rem' }}>
                {mission.mission.title}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={(mission.currentValue / mission.mission.targetValue) * 100}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  mb: 1,
                  backgroundColor: '#e0e0e0',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#D85252',
                  },
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">{mission.currentValue.toLocaleString()}</Typography>
                <Typography variant="body2">
                  {mission.mission.targetValue.toLocaleString()}{mission.mission.targetValue > 1000 ? '원' : '회'}
                </Typography>
              </Box>

            </Box>
          </CardContent>
        </Card>
      ))}

      <LastWeekMissionsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        lastWeekMissions={lastWeekMissions}
        handleAddPoints={handleAddPoints}
        refreshMissionData={refreshMissionData}
      />
    </Box>
  );
};

export default Mission;
