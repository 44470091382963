import axiosInstance from "../utils/axiosInstance";

export const authApi = {

  sendVerificationCode: async (queryParams: sendVerificationCodeQueryParams): Promise<void> => {
    try {
      const response = await axiosInstance.post('/email/sendEmail', null, {
        params: queryParams
      });
      console.log('response : ', response);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  },

  checkVerificationCode: async (queryParams: checkVerificationCodeQueryParams): Promise<void> => {
    try {
      const response = await axiosInstance.get('/email/authenticateEmailFake', {
        params: queryParams
      });
      console.log('response : ', response);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  },

  signUp: async (bodyParams: signUpRequest): Promise<void> => {
    try {
      const response = await axiosInstance.post('/users/signUp', bodyParams, {
        headers: {
          'Content-Type': 'application/json',
        }});
      console.log('response : ', response);
      return response.data;
    } catch (error) {
      console.log(error)
    }
  },

  signIn: async (loginData: LoginRequest): Promise<LoginResponse | null> => {
    try {
      const response = await axiosInstance.post<LoginResponse>('/auth/login', loginData);
      
      if (response.status === 200) {
        return response.data;
      } else {
        console.error('로그인 실패');
        return null;
      }
    } catch (error) {
      console.error('로그인 요청 중 오류 발생', error);
      return null;
    }
  },
};

export interface sendVerificationCodeQueryParams {
  email: string;
};

export interface checkVerificationCodeQueryParams {
  email: string;
  authenticationCode: string;
};

export interface signUpRequest {
  name: string;
  gender: string;
  email: string;
  password: string;
  age: number;
  job: string;
  salary: string;
  region: string;
};

interface LoginResponse {
  message: string;
  accessToken: string;
  email: string;
  refreshToken: string;
}

interface LoginRequest {
  email: string;
  password: string;
}
