import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { PieChart as PieChartComponent, BarChart as BarChartComponent } from '@mui/x-charts';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMbtiDetail } from '../../../api/get';
import '../../../style/chart.css';
import '../../../style/box.css';
import { CircularProgress } from '@mui/material';
import CurvedBackgroundTypography from '../CurvedBackgroundTypography';

// Types
interface PieChartData {
  chartType: 'PIE_CHART';
  title: string;
  count: number;
  list: {
    name: string;
    percent: number;
  }[];
}

interface BarChartData {
  chartType: 'BAR_CHART';
  title: string;
  count: number;
  maxValue: number;
  list: {
    name: string;
    amount: number;
  }[];
}

interface CountListData {
  chartType: 'COUNT_LIST';
  title: string;
  count: number;
  suffix: string;
  list: {
    name: string;
    count: number;
  }[];
}

type ConvenienceStoreData = {
  typeName: string;
  list: (PieChartData | BarChartData | CountListData)[];
};

const theme = createTheme();

const cardStyles = {
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const PieChart: React.FC<PieChartData> = ({ title, list }) => (
  <Card sx={{ ...cardStyles,display: 'flex', justifyContent: 'center', alignItems: 'center' ,padding:'10px'}}>
    <CardContent>
    <Typography variant="h6" style={{fontWeight:'700',fontSize:'20px'}}
        align="center" gutterBottom>{title}</Typography>
      <PieChartComponent
        series={[{
          data: list.map(item => ({ id: item.name, value: item.percent, label: item.name })),
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        }]}
        width={350}
        height={200}
      />
    </CardContent>
  </Card>
);

const BarChart: React.FC<BarChartData> = ({ title, list, maxValue }) => (
  <Card sx={{ ...cardStyles,display: 'flex', justifyContent: 'center', alignItems: 'center',padding:'10px' }}>
    <CardContent>
    <Typography variant="h6" style={{fontWeight:'700',fontSize:'20px'}}
        align="center" gutterBottom>{title}</Typography>
      <BarChartComponent
        width={350}
        height={300}
        series={[{
          data: list.map(item => item.amount),
          color: '#FFE27D',
        }]}
        xAxis={[{ scaleType: 'band', data: list.map(item => item.name) }]}
        yAxis={[{ max: maxValue }]}
        margin={{ left: 80, right: 20 }}
      />
    </CardContent>
  </Card>
);

const CountList: React.FC<CountListData> = ({ title, suffix, list }) => (
  <Card 
    sx={{ 
      ...cardStyles,
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      width: '100%',
    }}
  >
    <CardContent 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', // 수직 정렬
        justifyContent: 'center', // 수직으로 중앙 정렬
        alignItems: 'center', // 수평으로 중앙 정렬
        width: '100%',
        boxShadow:'none'
      }}
    >
      <Typography 
        variant="h6" 
        style={{ fontWeight: '700', fontSize: '20px' }}
        align="center" 
        gutterBottom
      >
        {title}
      </Typography>
      <List 
        style={{
          backgroundColor: '#FFFDE7',
          borderRadius: '10px',
          padding: '20px',
          width: '100%', 
          maxWidth: '700px', 
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          marginTop:'10px'
        }}
      >
        {list.map((item, index) => (
          <ListItem 
            key={index}
            style={{ 
              // 리스트의 마지막 항목이 아닌 경우에만 borderBottom을 추가
              borderBottom: index !== list.length - 1 ? '1px solid #FFC107' : 'none'
            }} 
            divider
          >
            <ListItemText primary={item.name} 
              primaryTypographyProps={{ 
                fontSize: '18px', // 원하는 폰트 크기로 변경
                fontWeight: 'bold', // 폰트 굵기를 설정하려면 추가
              }}/>
            <Typography variant="body2">
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {item.count.toLocaleString()} {/* 3자리마다 쉼표 추가 */}
            </span>
              {suffix}
            </Typography>
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
);

const Cafe: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {};
  const consumptionDetailId = data?.consumptionDetailId;
  const consumptionTypeId = data?.consumptionTypeId;
  const { name } = data?.item || {};

  const [convenienceStoreData, setConvenienceStoreData] = useState<ConvenienceStoreData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMbtiDetail(consumptionTypeId, consumptionDetailId);
        setConvenienceStoreData(response);
      } catch (error) {
        console.error('Failed to fetch convenience store data:', error);
      }
    };

    fetchData();
  }, [consumptionDetailId, consumptionTypeId]);

  if (!convenienceStoreData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      {/* <Typography variant="h5" component="h1" gutterBottom style={{fontWeight:'700',
        alignItems: 'center',display: 'flex', flexDirection: 'column'
      }}>{name}</Typography> */}
      <CurvedBackgroundTypography 
        text={convenienceStoreData.typeName}
        text2={name}
        backgroundImageUrl="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbjkYes%2FbtsJWwhkZzI%2FVeyP0f318DKlTbZQZNy8eK%2Fimg.png"
        iconUrl="https://ifh.cc/g/84VHwo.png"
      />
      <Box sx={{ width: '100%', padding: 0 }}>
        {convenienceStoreData.list.map((item, index) => (
          <Box key={index} mb={4}>
            {item.chartType === 'PIE_CHART' && (
              <PieChart {...item as PieChartData} />
            )}
            {item.chartType === 'BAR_CHART' && (
              <BarChart {...item as BarChartData} />
            )}
            {item.chartType === 'COUNT_LIST' && (
              <CountList {...(item as CountListData)} />
            )}
          </Box>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default Cafe;
