import React, { useState, useEffect, useRef, useCallback } from 'react';
import './VoiceRecognition.css';
import { Loader, Mic, Send } from 'lucide-react';
import coachImage from '../../assets/coach5.png';
import { chatApi, postIntendedMessageRequest, postMessageRequest } from '../../api/chatApi';
import { ACResponse, ACResponseComponent, AIResponse, DMResponse, DMResponseComponent, FTResponse, FTResponseComponent } from './response/AiCoachResponse';

interface InputData {
  text: string;
  source: 'microphone' | 'keyboard';
}

const NewAiCoach: React.FC = () => {
  const [isListening, setIsListening] = useState(false);
  const [inputData, setInputData] = useState<InputData>({ text: '', source: 'microphone' });
  const [inputMode, setInputMode] = useState<'microphone' | 'keyboard'>('microphone');
  const [submittedMessage, setSubmittedMessage] = useState('');
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const timeoutRef = useRef<number | null>(null);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isAnswerAnimating, setIsAnswerAnimating] = useState(false);
  const [aiResponse, setAiResponse] = useState<AIResponse | null>(null);
  const [shouldProcessInput, setShouldProcessInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const requestNormalQuestion = async (question: string) => {

    try {
      const postQuestionRequest: postMessageRequest = {
        message: inputData.text
      };
      const coachAnswer = await chatApi.postMessage(postQuestionRequest);
      if (coachAnswer != null) {
        console.log('api 실행 완료', inputData.text);
        const jsonPart = (coachAnswer as string).substring((coachAnswer as string).indexOf('{'));
        const parsedResponse = JSON.parse(jsonPart);

        const finalData: FTResponse = {
          type: 'ft',
          content: parsedResponse.response_message
        };

        setAiResponse(finalData);
      }
    } catch (error) {
      console.error('코치 응답 실패', error);
    } finally {
      setIsLoading(false);
    }
  }

  const requestDMQuestion = async (question: string) => {
    try {
      const postQuestionRequest: postMessageRequest = {
        message: question
      };
      const coachAnswer = await chatApi.postDmMessage(postQuestionRequest);
      if (coachAnswer != null) {

        if(coachAnswer.next_prob == -999 ) {
          const finalData: FTResponse = {
            type: 'ft',
            content: '다시 질문해주세요.'
          };

          setAiResponse(finalData);
        } else {
          const finalData: DMResponse = {
            type: 'dm',
            prev_prob: coachAnswer.prev_prob,
            next_prob: coachAnswer.next_prob
          };

          setAiResponse(finalData);
        }
      } else {
        const finalData: FTResponse = {
          type: 'ft',
          content: '다시 질문해주세요.'
        };

        setAiResponse(finalData);
      }
    } catch (error) {
      console.error('코치 응답 실패', error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleFinalInput = useCallback(async () => {
    if (inputData.text.trim()) {
      console.log('최종 입력:', inputData.text);
      setShowWelcome(false);
      setIsLoading(true);
      
      // API 호출 부분
      requestDMQuestion(inputData.text);

      // setIsLoading(false);
      setInputData({ text: '', source: 'microphone' });
      setShouldProcessInput(false);
    }
  }, [inputData.text]);

  const resetSilenceTimer = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    }, 2500);
  }, []);

  useEffect(() => {
    if (shouldProcessInput && !isListening) {
      setShowWelcome(false);
      handleFinalInput();
    }
  }, [shouldProcessInput, isListening, handleFinalInput]);

  useEffect(() => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const SpeechRecognitionAPI = (window.SpeechRecognition || window.webkitSpeechRecognition) as {
        new(): SpeechRecognition;
      };
      recognitionRef.current = new SpeechRecognitionAPI();

      if (recognitionRef.current) {
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;
        recognitionRef.current.lang = 'ko-KR';

        recognitionRef.current.onresult = (event: SpeechRecognitionEvent) => {
          const current = event.resultIndex;
          const transcript = event.results[current][0].transcript;
          setInputData({ text: transcript, source: 'microphone' });
          setSubmittedMessage(transcript);
          console.log('변환 중..', transcript);
          resetSilenceTimer();
        };

        recognitionRef.current.onend = () => {
          setIsListening(false);
          setShouldProcessInput(true);
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        };

        recognitionRef.current.onerror = (event: SpeechRecognitionErrorEvent) => {
          console.error('Speech recognition error', event.error);
          setIsListening(false);
        };
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [resetSilenceTimer]);

  const toggleListening = () => {
    if (isListening) {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else {
      setInputData({ text: '', source: 'microphone' });
      if (recognitionRef.current) {
        recognitionRef.current.start();
        resetSilenceTimer();
      }
    }
    setIsListening(!isListening);
  };

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ text: e.target.value, source: 'keyboard' });
  };

  const handleSubmit = async () => {
    if (inputData.text.trim()) {
      setSubmittedMessage(inputData.text);
      console.log('Submitted:', inputData);
      setIsLoading(true);
      setShowWelcome(false);

      // API 호출
      requestNormalQuestion(inputData.text);
    
      setShouldProcessInput(false);
      setInputData({ text: '', source: 'keyboard' });
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-140px)] bg-gray-100">
      <div className={`flex items-center justify-center transition-all duration-500 ease-in-out ${showWelcome ? 'h-[60vh]' : 'h-[30vh]'}`}>
        <div className={`flex ${showWelcome ? 'flex-col' : 'flex-row'} items-center justify-center w-full h-full`}>
          {showWelcome && (
            <div className={`text-2xl font-bold text-center mb-4 transition-all duration-500 ease-in-out ${isAnswerAnimating ? 'opacity-0 scale-90' : 'opacity-100 scale-100'}`}>
              궁금한게 있으면, <br />뭐든지 얘기해! ✋🏻 <br />
            </div>
          )}
          {!showWelcome && (
            <div className={`p-3 w-1/2 max-w-md transition-all duration-500 ease-in-out ${isAnswerAnimating ? 'opacity-0 scale-90' : 'opacity-100 scale-100'}`}>
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <Loader className="animate-spin" size={24} />
                  <span className="ml-2">응답을 생성 중입니다...</span>
                </div>
              ) : (
                aiResponse && (
                  <>
                    {aiResponse.type === 'dm' && <DMResponseComponent prev_prob={aiResponse.prev_prob} next_prob={aiResponse.next_prob} />}
                    {aiResponse.type === 'ac' && <ACResponseComponent availableAmount={aiResponse.availableAmount} categoryBalance={aiResponse.categoryBalance} />}
                    {aiResponse.type === 'ft' && <FTResponseComponent content={aiResponse.content} />}
                  </>
                )
              )}
            </div>
          )}
          <img
            src={coachImage}
            alt="AI Coach"
            className={`transition-all duration-500 ease-in-out ${showWelcome ? 'w-64 h-64' : 'w-32 h-32'} object-contain`}
          />
        </div>
      </div>

      {/* Hide AI Response Box if Welcome is Shown */}
      <div className={`flex-grow flex-col ${showWelcome ? 'hidden' : 'block'}`} />
      {/* 메인 컨텐츠 영역 */}
      <div className="flex-grow flex items-end justify-center pb-4">
        {/* 말풍선 */}
        <div className="w-full max-w-3xl px-4 flex justify-center">
          <div className="speech-bubble bg-white p-4 rounded-lg shadow-md text-center max-w-xl w-full">
            {inputMode === 'microphone'
              ? (isListening ? (submittedMessage || '듣고 있습니다..') : (submittedMessage || '마이크를 눌러 말씀하세요.'))
              : (submittedMessage || '키보드로 메시지를 입력하세요.')}
          </div>
        </div>
      </div>

      {/* 하단 고정 입력 영역 */}
      <div className="bg-white border-t border-gray-200 p-4">
        <div className="max-w-3xl mx-auto">
          <div className="flex items-center justify-center mb-4">
            {inputMode === 'microphone' ? (
              <button
                className={`w-14 h-14 rounded-full flex items-center justify-center text-white shadow-lg ${isListening ? 'bg-red-500' : 'bg-[#11C2B0]'}`}
                onClick={toggleListening}
              >
                <Mic size={24} />
              </button>
            ) : (
              <div className="relative w-full">
                <input
                  type="text"
                  value={inputData.text}
                  onChange={handleTextInput}
                  className="w-full h-14 px-6 pr-16 rounded-full border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#11C2B0] focus:border-transparent shadow-lg"
                  placeholder="텍스트를 입력하세요."
                />
                <button
                  onClick={handleSubmit}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-[#11C2B0] text-white h-10 w-10 flex items-center justify-center rounded-full"
                >
                  <Send size={20} />
                </button>
              </div>
            )}
          </div>
          <div className="flex justify-center">
            <div className="inline-flex bg-gray-200 rounded-full p-1">
              <button
                className={`px-4 py-2 rounded-full text-sm ${inputMode === 'microphone' ? 'bg-white shadow' : ''}`}
                onClick={() => setInputMode('microphone')}
              >
                음성 입력
              </button>
              <button
                className={`px-4 py-2 rounded-full text-sm ${inputMode === 'keyboard' ? 'bg-white shadow' : ''}`}
                onClick={() => setInputMode('keyboard')}
              >
                텍스트 입력
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAiCoach;
