import React, { useState, useEffect, useMemo, useRef, useCallback, useLayoutEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, getDate, getDaysInMonth, subDays, addDays, isBefore, isToday, isFirstDayOfMonth, startOfWeek, endOfWeek, isSameDay } from 'date-fns';
import { bankingApi, TransactionItem } from '../../api/bankingApi';
import { convertStringToDate, convertTimestampToDate, formatDateToMonthDay, formatTimeToHourMinute, getDayFromDateString, getTimestampFromDate, parseISODate } from '../../utils/formatUtil';
import { ActiveShape } from 'recharts/types/util/types';
import { Stack, Typography } from '@mui/material';
import SpendingItem from './components/SpendingItem';
import { SpendingItemType } from '../../utils/enumUtil';
import { colors } from '../../styles/colors';
import { sortTransactionsByConsumptionTime } from '../../utils/sortUtil';

// 데이터 포인트 인터페이스 정의
interface DataPoint {
  date: number;
  rate: number | null;
}

interface CustomDotProps {
  cx: number;
  cy: number;
  payload: DataPoint;
  onClick: (event: React.MouseEvent<SVGElement>, payload: DataPoint) => void;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ value: number }>;
  label?: number;
}

// 현재 월의 데이터 포인트 생성 함수
function generateDayLastDataPoints(currentDay: number): DataPoint[] {
  const dataPoints: DataPoint[] = [];

  for (let i = 1; i <= 31; i++) {
    dataPoints.push({
      date: i,
      rate: null
    });
  }

  return dataPoints;
}

function generateDataPoints(currentDay: number): DataPoint[] {
  const dataPoints: DataPoint[] = [];

  for (let i = 1; i <= 31; i++) {
    dataPoints.push({
      date: getTimestampForDaysAfterFirstOfMonth(i - 1),
      rate: i <= currentDay ? 100 : null
    });
  }

  return dataPoints;
}

function getTimestampForDaysAfterFirstOfMonth(daysAfter: number): number {
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const targetDate = new Date(firstDayOfMonth);
  targetDate.setDate(firstDayOfMonth.getDate() + daysAfter);

  return targetDate.getTime();
}

const ConsumptionGraph: React.FC = () => {
  // 상태 관리
  const [data, setData] = useState<DataPoint[]>(generateDataPoints(1));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [transActions, setTransActions] = useState<TransactionItem[]>([]);
  const [selectedPoint, setSelectedPoint] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date()); // 오늘 날짜로 초기화
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedPointDate, setSelectedPointDate] = useState<Date | null>(new Date());

  // 현재 월 가져오기
  const getCurrentMonth = () => {
    return format(new Date(), 'M월');
  };

  // 데이터 fetching
  useEffect(() => {
    const fetchData = async () => {
      const today = new Date().getDate();
      const dayLastdataPointArray: DataPoint[] = generateDayLastDataPoints(today);
      const dataPointArray: DataPoint[] = generateDataPoints(today);

      try {
        setIsLoading(true);
        const userTransaction = await bankingApi.getTransactionList();

        if (userTransaction != null) {
          setTransActions(userTransaction);

          const sortedTransActions = sortTransactionsByConsumptionTime(userTransaction);

          sortedTransActions.forEach((transaction) => {
            const current_timestamp = getTimestampFromDate(parseISODate(transaction.consumptionTime));
            const current_day = parseISODate(transaction.consumptionTime).getDate();
            dayLastdataPointArray[current_day - 1].date = current_timestamp;
            dayLastdataPointArray[current_day - 1].rate = transaction.successProbability;
          });

          const cur_day = new Date().getDate();

          Array.from({ length: cur_day }).forEach((_, index) => {
            if (dayLastdataPointArray[index].rate != null) {
              dataPointArray[index].rate = dayLastdataPointArray[index].rate;
            } else {
              if (index != 0) {
                dataPointArray[index].rate = dataPointArray[index - 1].rate;
              }
            }
          })
          setData(dataPointArray);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : '알 수 없는 오류가 발생했습니다');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // 현재 성공 확률 계산
  const currentSuccessRate = useMemo(() => {
    const validData = data.filter(d => d.rate !== null);
    return validData.length > 0 ? validData[validData.length - 1].rate : null;
  }, [data]);

  // x축 도메인 계산
  const xAxisDomain = useMemo(() => {
    if (data.length === 0) return [0, 1];
    const firstDay = new Date(data[0].date);
    const lastDay = new Date(data[data.length - 1].date);
    return [
      subDays(firstDay, 1).getTime(),
      addDays(lastDay, 1).getTime()
    ];
  }, [data]);

  // x축 포맷 함수
  const formatXAxis = (timestamp: number) => {
    const date = new Date(timestamp);
    const day = getDate(date);
    if (day === 1 || day === 10 || day === 20 || day === getDaysInMonth(date)) {
      return `${day}일`;
    }
    return '';
  };

  // 커스텀 툴팁 컴포넌트
  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].value !== null && label) {
      const isSelected = selectedPointDate && isSameDay(new Date(label), selectedPointDate);
      return (
        <div className={`bg-white p-2 border ${isSelected ? 'border-red-500' : 'border-gray-300'} rounded shadow`}>
          <p className="text-sm">{format(new Date(label), 'yyyy.MM.dd')}</p>
          <p className="text-sm font-bold">{`${payload[0].value.toFixed(1)}%`}</p>
          {!isSelected && <p className="text-xs text-gray-500">클릭하여 선택</p>}
        </div>
      );
    }
    return null;
  };

  // 점 클릭 핸들러
  const handleDotClick = useCallback((event: React.MouseEvent<SVGElement>, payload: DataPoint) => {
    console.log("handleDotClick called", payload);  // 디버깅을 위한 로그
    if (payload && payload.date) {
      const clickedDate = new Date(payload.date);
      setSelectedPointDate(clickedDate);
      setSelectedDate(clickedDate);
    }
  }, []);

  // 커스텀 도트 컴포넌트
  const CustomDot: React.FC<CustomDotProps> = ({ cx, cy, payload, onClick }) => {
    const isSelected = selectedPointDate && isSameDay(new Date(payload.date), selectedPointDate);

    if (payload.rate === null) {
      return null;
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={isSelected ? 8 : 4}
        fill={isSelected ? "#11C2B0" : "#A4A4A4"}
        stroke={isSelected ? "#FF3333" : "none"}
        strokeWidth={2}
        onClick={(event) => {
          console.log("CustomDot clicked", payload);  // 디버깅을 위한 로그
          onClick(event, payload);
        }}
        style={{ cursor: 'pointer' }}
      />
    );
  };

  // 캘린더 날짜 계산
  const monthDates = useMemo(() => {
    const start = startOfMonth(selectedDate);
    const end = endOfMonth(selectedDate);
    return eachDayOfInterval({ start, end });
  }, [selectedDate]);

  const handleDateClick = useCallback((date: Date, currentScrollPosition: number) => {
    setSelectedDate(date);
    setScrollPosition(currentScrollPosition);
    setSelectedPointDate(date);
  }, []);

  // 캘린더 컴포넌트
  const Calendar: React.FC = () => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const initialScrollRef = useRef<boolean>(true);

    useLayoutEffect(() => {
      console.log('실행 완료');
      if (scrollContainerRef.current) {
        const selectedDateButton = scrollContainerRef.current.querySelector(`[data-date="${selectedDate.toISOString()}"]`);
        if (selectedDateButton) {
          if (initialScrollRef.current) {
            selectedDateButton.scrollIntoView({ block: 'nearest', inline: 'center' });
            initialScrollRef.current = false;
          } else {
            selectedDateButton.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          }
        } else {
          const allButtons = scrollContainerRef.current.querySelectorAll('button');
          const buttonsArray = Array.from(allButtons);
          const closestButton = buttonsArray.reduce((prev, curr) => {
            const prevDate = new Date(prev.getAttribute('data-date') || '');
            const currDate = new Date(curr.getAttribute('data-date') || '');
            return Math.abs(prevDate.getTime() - selectedDate.getTime()) < Math.abs(currDate.getTime() - selectedDate.getTime()) ? prev : curr;
          });

          if (closestButton) {
            closestButton.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          }
        }
      }
    }, [selectedDate]);

    const handleDateButtonClick = (date: Date) => {
      setSelectedDate(date);
      setSelectedPointDate(date);
    };

    return (
      <div
        className="mt-4 bg-gray-100 rounded-lg p-2 overflow-x-auto"
        style={hideScrollbarStyle}
        ref={scrollContainerRef}
      >
        <div className="flex space-x-2 min-w-max">
          {monthDates.map((date) => (
            <button
              key={date.toISOString()}
              data-date={date.toISOString()}
              onClick={() => handleDateButtonClick(date)}
              className={`w-12 h-16 rounded-lg flex flex-col items-center justify-center text-sm
                ${isSameDay(date, selectedDate)
                  ? 'text-white'
                  : 'text-[' + colors.zozal_grey + ']'}`}
              style={{
                backgroundColor: isSameDay(date, selectedDate) ? colors.zozal_green : 'white',
              }}
            >
              <span className="text-xs font-semibold">{format(date, 'EEE').toUpperCase()}</span>
              <span className="text-lg font-bold">{format(date, 'd')}</span>
            </button>
          ))}
        </div>
      </div>
    );
  };

  // 스크롤바 숨김 스타일
  const hideScrollbarStyle = {
    msOverflowStyle: 'none',  // IE and Edge
    scrollbarWidth: 'none',   // Firefox
    '&::-webkit-scrollbar': { // Chrome, Safari and Opera
      display: 'none'
    }
  } as React.CSSProperties;

  if (isLoading) return <div>데이터를 불러오는 중...</div>;
  if (error) return <div>에러: {error}</div>;

  // 선택된 날짜에 해당하는 거래 필터링
  const filteredTransactions = transActions.filter(transaction =>
    parseISODate(transaction.consumptionTime).getDate() === selectedDate.getDate()
  );

  return (
    <div className="px-8 py-4 bg-white rounded-lg shadow space-y-6">
      {/* 소비 성공 확률 및 그래프 섹션 */}
      <div className="bg-gray-50 p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-xl font-bold mb-1">{getCurrentMonth()} 소비 성공 확률</h2>
            <p className="text-sm text-gray-600">
              현재 성공확률 {' '}
              <span className="text-[#11C2B0] font-bold">
                {currentSuccessRate !== null ? `${currentSuccessRate.toFixed(1)}%` : 'N/A'}
              </span>
            </p>
          </div>
          <p className="text-sm text-gray-600">{format(new Date(), 'yyyy.MM.dd')}</p>
        </div>
        <div className="h-72">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              onMouseLeave={() => setSelectedPoint(null)}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="date"
                tickFormatter={formatXAxis}
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12 }}
                interval={0}
                domain={xAxisDomain as [number, number]}
                type="number"
                scale="time"
              />
              <YAxis hide domain={[0, 100]} />
              <Tooltip content={<CustomTooltip />} />
              <ReferenceLine y={50} stroke="#A8E0D9" strokeDasharray="3 3" />
              <Line
                type="monotone"
                dataKey="rate"
                stroke="#11C2B0"
                strokeWidth={3}
                dot={(props) => {
                  return <CustomDot {...props} onClick={handleDotClick} />;
                }}
                activeDot={{
                  r: 6,
                  fill: '#11C2B0',
                  onClick: (props: any) => {
                    if (props && props.payload) {
                      handleDotClick(props.event, props.payload);
                    }
                  }
                }}
                connectNulls={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* 캘린더 섹션 */}
      <div className="bg-white border border-gray-200 rounded-lg p-4">
        <h3 className="text-lg font-semibold mb-4">날짜 선택</h3>
        <Calendar />
      </div>

      {/* 소비 내역 섹션 */}
      <div className="bg-gray-50 rounded-lg p-4">
        <h3 className="text-lg font-semibold mb-4">소비 내역</h3>
        {filteredTransactions.length > 0 ? (
          <Stack direction='column' spacing={2}>
            {filteredTransactions.map((transAction) => (
              <SpendingItem
              key={transAction.id}
              SpendingItemTitle={transAction.description}
              SpendingItemTime={formatTimeToHourMinute(transAction.consumptionTime)}
              SpendingItemDate={formatDateToMonthDay(transAction.consumptionTime)}
              SpendingAmount={transAction.amount.toLocaleString()}
              SpendingCategory={transAction.type}
              CumulativeSpending={0}
              SuccessAttribution={transAction.successContribution}
              SuccessProbability={transAction.successProbability}
              ItemType={SpendingItemType.Plain}
              transactionAfterBalance={'0'} />
            ))}
          </Stack>
        ) : (
          <Typography variant="body1" className="text-center">
            해당 날짜에 소비 내역이 없습니다.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ConsumptionGraph;
