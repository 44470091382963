import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/ErrorModal";

const colors = {
  zozal_green: '#11C2B0',
  zozal_grey: '#A4A4A4',
};

function QrScanScreen() {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showError, setShowError] = useState(false);

  const handleScan = (result: QrScanner.ScanResult) => {
    try {
      const parsedData: StoreCodeInfo = JSON.parse(result.data);
      console.log(result.data);

      if(parsedData.domainId == "https://j11d103.p.ssafy.io/"){
        const data = { storeId: parsedData.destinataryId, storeName: parsedData.destinatary };
        navigate('../scanResult', {state: data});
      } else {
        console.log('올바르지 않는 QR 코드');
        console.log(parsedData.domainId);
        simulateScanError();
      }
    
    } catch (error) {
      console.error("Failed to parse QR code data:", error);
    }
  };

  const handleScanError = () => {
    setShowError(true);
  };

  const simulateScanError = () => {
    handleScanError();
  };

  useEffect(() => {
    const videoElem = videoRef.current;
    if (videoElem) {
      const qrScanner = new QrScanner(
        videoElem,
        handleScan,
        {
          returnDetailedScanResult: true,
          ...QrOptions
        }
      );
      qrScanner.start();

      return () => qrScanner.destroy();
    }
  }, []);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    }}>
      <video 
        ref={videoRef} 
        style={{ 
          width: '100%', 
          height: '100%', 
          objectFit: 'cover',
          position: 'absolute',
        }} 
        autoPlay 
        playsInline 
      />
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85vw',
        height: '85vw',
        maxWidth: '450px',
        maxHeight: '450px',
        border: '2px solid white',
        borderRadius: '20px',
        boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)'
      }}>
        {/* 모서리 강조 선 */}
        <div style={{
          position: 'absolute',
          top: '-3px',
          left: '-3px',
          width: '40px',
          height: '40px',
          borderTop: `6px solid ${colors.zozal_green}`,
          borderLeft: `6px solid ${colors.zozal_green}`,
          borderTopLeftRadius: '20px'
        }} />
        <div style={{
          position: 'absolute',
          top: '-3px',
          right: '-3px',
          width: '40px',
          height: '40px',
          borderTop: `6px solid ${colors.zozal_green}`,
          borderRight: `6px solid ${colors.zozal_green}`,
          borderTopRightRadius: '20px'
        }} />
        <div style={{
          position: 'absolute',
          bottom: '-3px',
          left: '-3px',
          width: '40px',
          height: '40px',
          borderBottom: `6px solid ${colors.zozal_green}`,
          borderLeft: `6px solid ${colors.zozal_green}`,
          borderBottomLeftRadius: '20px'
        }} />
        <div style={{
          position: 'absolute',
          bottom: '-3px',
          right: '-3px',
          width: '40px',
          height: '40px',
          borderBottom: `6px solid ${colors.zozal_green}`,
          borderRight: `6px solid ${colors.zozal_green}`,
          borderBottomRightRadius: '20px'
        }} />
      </div>

      {/* Footer */}
      <div style={{
        position: 'absolute',
        bottom: '15%',
        left: '0',
        width: '100%',
        textAlign: 'center',
        color: 'white',
        fontSize: '16px',
        padding: '0 20px'
      }}>
        QR 코드를 스캔 영역 안에 위치시켜 주세요
      </div>

      <ErrorModal 
        isOpen={showError} 
        onClose={() => setShowError(false)}
      />
    </div>
  );
}

export const QrOptions = {
  preferredCamera: "environment",
  maxScansPerSecond: 60,
  highlightScanRegion: false,
  highlightCodeOutline: true,
  scanRegion: {
    x: 0.075,
    y: 0.075,
    width: 0.85,
    height: 0.85
  }
};

export default QrScanScreen;

interface StoreCodeInfo {
  domainId: string;
  destinataryId: number,
  destinatary: string,
}
