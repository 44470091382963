import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../../styles/colors';


const AuthButton = styled(Button)({
  marginTop: '20px',
  width: '100%',
  padding: '10px',
  fontSize: '16px',
  backgroundColor: colors.zozal_green,
  color: 'white',
  '&:hover': {
    backgroundColor: colors.zozal_grey,
  },
});

const ErrorText = styled(Typography)({
  color: 'red',
  fontSize: '0.75rem',
  marginTop: '5px',
});

type SignUpStep4Props = {
  userData: {
    password: string;
    confirmPassword: string;
  };
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  onSignUp: (e: React.FormEvent) => void;
};

function SignUpStep4({ userData, setUserData, onSignUp }: SignUpStep4Props) {
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevData: any) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const validateField = (fieldName: string, value: string) => {
    let error = '';
    switch (fieldName) {
      case 'password':
        if (value.length < 8) {
          error = '비밀번호는 최소 8자 이상이어야 합니다.';
        }
        break;
      case 'confirmPassword':
        if (value !== userData.password) {
          error = '비밀번호가 일치하지 않습니다.';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (userData.password === userData.confirmPassword && !errors.password && !errors.confirmPassword) {
      onSignUp(e);
    } else {
      alert('비밀번호를 확인해주세요.');
    }
  };

  return (
    <>
      <TextField
        label="비밀번호"
        variant="outlined"
        fullWidth
        margin="normal"
        type="password"
        required
        name="password"
        value={userData.password}
        onChange={handleChange}
        error={!!errors.password}
        helperText={errors.password}
      />
      <TextField
        label="비밀번호 확인"
        variant="outlined"
        fullWidth
        margin="normal"
        type="password"
        required
        name="confirmPassword"
        value={userData.confirmPassword}
        onChange={handleChange}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
      />
      <AuthButton type="submit" onClick={handleSubmit}>
        회원가입 완료
      </AuthButton>
    </>
  );
}

export default SignUpStep4;
