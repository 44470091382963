import React from 'react';
import Typography from '@mui/material/Typography';

interface CurvedBackgroundTypographyProps {
  text: string;
  text2?: string; // text2 속성 추가
  backgroundImageUrl?: string;
  iconUrl: string;
}

const CurvedBackgroundTypography: React.FC<CurvedBackgroundTypographyProps> = ({ 
  text, 
  text2, // text2 사용
  backgroundImageUrl,
  iconUrl
}) => {
  const containerStyle: React.CSSProperties = {
    backgroundImage: `url(${backgroundImageUrl || '/api/placeholder/800/600'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderBottomLeftRadius: '50% 40px',
    borderBottomRightRadius: '50% 40px',
    position: 'relative' as 'relative',
    overflow: 'visible',  
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  };

  const typographyStyle: React.CSSProperties = {
    color: 'black',
    textShadow: '2px 2px 4px rgba(255,255,255,0.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    zIndex: 1,
    flex: 1
  };

  const imageContainerStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '-70px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    zIndex: 1,
  };

  const imageStyle: React.CSSProperties = {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
  };

  const outerContainerStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    position: 'relative',
  };

  const spacerStyle = {
    height: '50px',
  };

  return (
    <div style={outerContainerStyle}>
      <div style={containerStyle}>
        <div style={imageContainerStyle}>
          <img src={iconUrl} alt="Icon" style={imageStyle} />
        </div>
      </div>
      <div style={spacerStyle}></div>
      {text2 && (
        <Typography variant="h5" style={{...typographyStyle, fontSize: '2.0rem',paddingTop:'20px'}}>
          {text2}
        </Typography>
      )}
      <Typography variant="h6" style={{...typographyStyle, fontSize: '1.4rem',padding:'10px',paddingBottom:'20px'}}>
          {text}
      </Typography>
    </div>
  );
};

export default CurvedBackgroundTypography;
