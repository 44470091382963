import axiosInstance from "../utils/axiosInstance";

export const chatApi = {

  postMessage: async (queryParams: postMessageRequest): Promise<void | null> => {
    try {
      const response = await axiosInstance.post('/chat/send-message',null, {
        params: queryParams
      });
      
      if(response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  },

  postDmMessage: async (queryParams: postMessageRequest): Promise<DmResponse | null> => {
    const intendedQueryParams: postIntendedMessageRequest = {
      type: 1,
      requestMessage: queryParams.message
    };
    try {
      const response = await axiosInstance.post<DmResponse>('/chat-params', intendedQueryParams);
      
      if(response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  },

  postAcMessage: async (queryParams: postMessageRequest): Promise<AcResponse | null> => {
    const intendedQueryParams: postIntendedMessageRequest = {
      type: 2,
      requestMessage: queryParams.message
    };
    try {
      const response = await axiosInstance.post<AcResponse>('/chat/chat-params', intendedQueryParams);
      
      if(response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  },
};

export interface postMessageRequest {
  message: string;
};

export interface postIntendedMessageRequest {
  type: number;
  requestMessage: string;
}

export interface DmResponse {
  prev_prob: number;
  next_prob: number;
}

export interface AcResponse {
  totalAvailable: number;
  categoryAvailable: number;
}
