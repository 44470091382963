import React from 'react';
import { motion } from 'framer-motion';
import zozalLogo from '../../assets/zozal_logo.png';

function SplashScreen() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#ffffff',
    }}>
      <motion.div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
          maxWidth: '80%', // 부제목의 가로 길이와 비슷하게 제한
          width: '100%',
        }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img
          src={zozalLogo}
          alt="Zozal Logo"
          style={{ height: '60px', marginRight: '15px' }}
        />
        <h1 style={{ 
          fontSize: '48px', 
          fontWeight: 'bold',
          margin: 0,
          height: '60px',
          lineHeight: '60px',
          color: '#6E6E6E'
        }}>
          아낌나무
        </h1>
      </motion.div>
      <motion.p
        style={{ 
          fontSize: '18px', 
          color: '#666666',
          maxWidth: '80%', // 텍스트가 너무 길어지지 않도록 제한
          textAlign: 'center',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        새로운 소비문화, 자산관리의 시작
      </motion.p>
    </div>
  );
}

export default SplashScreen;
