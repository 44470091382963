import { Button, ButtonProps, styled } from "@mui/material";
import { colors } from "../styles/colors";

const StyledButton = styled(Button)(({ disabled }) => ({
  marginTop: '20px',
  width: '100%',
  padding: '10px',
  fontSize: '16px',
  backgroundColor: disabled ? colors.zozal_grey : colors.zozal_green,
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: disabled ? colors.zozal_grey : colors.zozal_green,
  },
  '&:disabled': {
    backgroundColor: colors.zozal_grey,
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

interface BasicButtonProps extends ButtonProps {
  children: React.ReactNode;
}

const BasicButton: React.FC<BasicButtonProps> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default BasicButton;
