import axiosInstance from "../utils/axiosInstance";

export const spendGoalApi = {
  fetchUserConsumptionGoals: async (): Promise<fetchUserConsumptionGoalsResponse | null> => {
    try {
      const response = await axiosInstance.get<fetchUserConsumptionGoalsResponse>('/consumptionGoal');

      if (response.status === 200) {
        return response.data;
      } else {
        console.error('소비 목표 Fetch 실패');
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  },

  postUserConsumptionGoals: async (body_params: postUserConsumptionGoalsParams): Promise<void | null> => {
    const data = {
      list: [
        {
          consumptionType: "FOOD",
          targetAmount: body_params.foodGoal
        },
        {
          consumptionType: "LIVING",
          targetAmount: body_params.livingGoal
        },
        {
          consumptionType: "TRANSPORTATION",
          targetAmount: body_params.transportationGoal
        },
        {
          consumptionType: "CAFE_AND_SNACK",
          targetAmount: body_params.cafeAndsnackGoal
        },
        {
          consumptionType: "LEISURE",
          targetAmount: body_params.leisureGoal
        },
      ]
    };
    
    try {
      const response = await axiosInstance.post('/consumptionGoal', data);
      
      if (response.status === 200) {
        return response.data;
      } else if(response.status === 409) {
        console.error('이미 소비목표가 생성되어있습니다.');
        return null;
      } else {
        return null;
      }
    } catch (error) {
      console.error('소비 목표 생성중 오류 발생', error);
      return null;
    }
  },
};

export interface postUserConsumptionGoalsParams {
  foodGoal: number;
  livingGoal: number;
  transportationGoal: number;
  cafeAndsnackGoal: number;
  leisureGoal: number;
};

export interface fetchUserConsumptionGoalsResponse {
  count: string;
  list: SpendingGoalItem[];
}

export interface SpendingGoalItem {
  consumptionType: string;
  targetAmount: number;
  spentAmount: number;
}
