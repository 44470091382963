import { Route, Routes } from "react-router-dom";
import Cafe from "./Cafe/Cafe";
import Hobby from "./Hobby/Hobby";
import Transport from "./Transport/Transport";
import Life from "./Life/Life";
import Food from "./Food/Food";
import Food1 from "./Food/Food1";
import Food2 from "./Food/Food2";
import Food3 from "./Food/Food3";
import Transport1 from "./Transport/Transport1";
import Transport2 from "./Transport/Transport2";
import Transport3 from "./Transport/Transport3";
import Hobby1 from "./Hobby/Hobby1";
import Hobby2 from "./Hobby/Hobby2";
import Hobby3 from "./Hobby/Hobby3";
import Cafe1 from "./Cafe/Cafe1";
import Cafe2 from "./Cafe/Cafe2";
import Cafe3 from "./Cafe/Cafe3";
import Life1 from "./Life/Life1";
import Life2 from "./Life/Life2";
import Life3 from "./Life/Life3";
import Life4 from "./Life/Life4";
import Analysis from "./Analysis";

function AnalysisContainer() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Analysis/>}/>
        {/* 카테고리별 상세 페이지 */}
        <Route path="/cafe" element={<Cafe />} />
        <Route path="/hobby" element={<Hobby />} />
        <Route path="/transport" element={<Transport />} />
        <Route path="/life" element={<Life />} />
        <Route path="/food" element={<Food />} />

        {/* 음식 상세 페이지 */}
        <Route path="/food/1" element={<Food1 />} />
        <Route path="/food/2" element={<Food2 />} />
        <Route path="/food/3" element={<Food3 />} />

        {/* 교통 통신 */}
        <Route path="/transport/4" element={<Transport1 />} />
        <Route path="/transport/5" element={<Transport2 />} />
        <Route path="/transport/6" element={<Transport3 />} />
        {/* 취미 */}
        <Route path="/hobby/10" element={<Hobby1 />} />
        <Route path="/hobby/11" element={<Hobby2 />} />
        <Route path="/hobby/12" element={<Hobby3 />} />
        {/* 카페 편의점 */}
        <Route path="/cafe/7" element={<Cafe1 />} />
        <Route path="/cafe/8" element={<Cafe2 />} />
        <Route path="/cafe/9" element={<Cafe3 />} />
        {/* 생활 */}
        <Route path="/life/13" element={<Life1 />} />
        <Route path="/life/14" element={<Life2 />} />
        <Route path="/life/15" element={<Life3 />} />
        <Route path="/life/16" element={<Life4 />} />
      </Routes>
    </>
  );
};

export default AnalysisContainer;
