import React from 'react';

export type TabType = 'best' | 'worst';

interface ToggleTabProps {
  activeTab: TabType;
  setActiveTab: (tab: TabType) => void;
}

const ToggleTab = ({ activeTab, setActiveTab }: ToggleTabProps) => {
  return (
    <div className="flex w-full rounded-full bg-gray-200 p-1">
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-colors duration-200 ${
          activeTab === 'best'
            ? 'bg-white text-gray-800 shadow'
            : 'text-gray-500 hover:text-gray-700'
        }`}
        onClick={() => setActiveTab('best')}
      >
        BEST 소비 TOP 3
      </button>
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-colors duration-200 ${
          activeTab === 'worst'
            ? 'bg-white text-gray-800 shadow'
            : 'text-gray-500 hover:text-gray-700'
        }`}
        onClick={() => setActiveTab('worst')}
      >
        WORST 소비 TOP 3
      </button>
    </div>
  );
};

export default ToggleTab;
