import React from 'react';

interface CardItemProps {
  title: string;
  onClick: () => void;
}

export const CardItem: React.FC<CardItemProps> = ({ title, onClick }) => (
  <div
    onClick={onClick}
    className="p-5 cursor-pointer hover:bg-gray-100 transition-colors"
  >
    <div className="flex justify-between items-center">
      <div className="text-sm font-medium text-gray-700">
        {title}
      </div>
      <div className="text-gray-500">
        &gt;
      </div>
    </div>
  </div>
);

interface CardContainerProps {
  children: React.ReactNode;
}

export const CardContainer: React.FC<CardContainerProps> = ({ children }) => (
  <div className="bg-white shadow-lg rounded-lg overflow-hidden">
    {children}
  </div>
);