import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import zozalLogo from '../assets/zozal_logo.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

function TopBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const showBackButton = location.pathname !== '/Home'
    && location.pathname !== '/Analysis'
    && location.pathname !== '/AiCoach'
    && location.pathname !== '/Mission'
    && location.pathname !== '/MyPage';

  return (
    <div className="fixed top-0 left-0 right-0 bg-white shadow-sm z-10">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            {showBackButton ? (
              <button onClick={() => navigate(-1)} className="mr-2">
                <ArrowBackIcon />
              </button>
            ) : (
              <div className="flex-shrink-0">
                <img className="w-8 filter" src={zozalLogo} alt="Logo" />
              </div>
            )}
            <span className="ml-3 text-gray-500 font-bold text-xl">아낌나무</span>
          </div>
          <div className="flex items-center">
            <button className="mr-2" onClick={() => navigate('Pay')}>
              <QrCodeScannerIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
