import React from 'react';
import { TextField, Button, Typography, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../../styles/colors';
import { Man, Woman } from '@mui/icons-material';

const AuthButton = styled(Button)({
  marginTop: '20px',
  width: '100%',
  padding: '10px',
  fontSize: '16px',
  backgroundColor: colors.zozal_green,
  color: 'white',
  '&:hover': {
    backgroundColor: colors.zozal_grey,
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  width: '100%',
  '& .MuiToggleButtonGroup-grouped': {
    margin: 5,
    border: 0,
    '&:not(:first-of-type)': {
      borderRadius: 4,
    },
    '&:first-of-type': {
      borderRadius: 4,
    },
  },
});

const StyledToggleButton = styled(ToggleButton)({
  width: '50%',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: colors.zozal_green,
    backgroundColor: 'rgba(0, 128, 0, 0.1)',
  },
});

type SignUpStep2Props = {
  userData: {
    name: string;
    gender: string;
    age: number;
  };
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  onNextStep: () => void;
};

function SignUpStep2({ userData, setUserData, onNextStep }: SignUpStep2Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleGenderChange = (
    event: React.MouseEvent<HTMLElement>,
    newGender: string | null,
  ) => {
    if (newGender !== null) {
      setUserData((prevData: any) => ({ ...prevData, gender: newGender }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onNextStep();
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="이름"
        variant="outlined"
        fullWidth
        margin="normal"
        required
        name="name"
        value={userData.name}
        onChange={handleChange}
      />
      <Grid container spacing={2} alignItems="center" style={{ marginTop: '16px' }}>
      <Grid item xs={12} sm={6}>
          <TextField
            label="나이"
            variant="outlined"
            fullWidth
            required
            name="age"
            type="number"
            value={userData.age}
            onChange={handleChange}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledToggleButtonGroup
            value={userData.gender}
            exclusive
            onChange={handleGenderChange}
            aria-label="gender"
          >
            <StyledToggleButton value="남성" aria-label="male">
              <Man /> 남성
            </StyledToggleButton>
            <StyledToggleButton value="여성" aria-label="female">
              <Woman /> 여성
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
        
      </Grid>
      <AuthButton type="submit"  onClick={handleSubmit}>
        다음
      </AuthButton>
    </form>
  );
}

export default SignUpStep2;
