import React from "react";
import { Button, Paper, Typography, Box } from "@mui/material";
import { Target } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';

const zozal_green = '#11C2B0';
const zozal_grey = '#A4A4A4';

function ExpenseGoalAlert() {
  const navigate = useNavigate();

  return (
    <Paper 
      elevation={0}
      sx={{ 
        borderRadius: 3,
        border: `1px solid ${alpha(zozal_green, 0.3)}`,
        overflow: 'hidden',
        my: 2,
        bgcolor: alpha(zozal_green, 0.05),
      }}
    >
      <Box sx={{ 
        p: 3, 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Target size={32} color={zozal_green} />
        <Typography variant="h6" sx={{ mt: 2, fontWeight: 'medium', color: 'text.primary' }}>
          이번 달 소비 목표를 설정해보세요
        </Typography>
        <Typography variant="body2" align="center" sx={{ mt: 1, mb: 2, color: 'text.secondary' }}>
          소비 목표 설정으로 더 효과적인 지출 관리를 시작해보세요.
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => navigate('/SpendingGoal')}
          sx={{ 
            mt: 1, 
            px: 3, 
            py: 1, 
            borderRadius: 2,
            fontWeight: 'medium',
            bgcolor: zozal_green,
            color: 'white',
            '&:hover': {
              bgcolor: alpha(zozal_green, 0.8),
            }
          }}
        >
          소비 목표 설정하기
        </Button>
      </Box>
    </Paper>
  );
};

export default ExpenseGoalAlert;
