import React from 'react';

type UserInfoComponentsProps = {
  name: string;
  description: string;
};

function UserInfoComponents({ name, description }: UserInfoComponentsProps) {
  return (
    <div
        className="px-5 py-3 cursor-pointer hover:bg-gray-100 transition-colors"
    >
        <div className="flex justify-between items-center">
            <div className="text-sm font-medium text-gray-500">
                {name}
            </div>
            <div className="text-sm font-medium text-gray-900">
                {description}
            </div>
        </div>
    </div>
  );
}

export default UserInfoComponents;