import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserInfo } from "../../api/get";
import axios from "axios";
import UserInfoEditComponent from "./components/UserInfoEditComponent";
import { tokenUtils } from "../../utils/tokenUtil";

// Modal component with TypeScript props
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

// Define the interface for the user data
interface UserData {
  id: number;
  userKey: string;
  name: string;
  gender: string;
  email: string;
  password: string;
  age: number;
  job: string;
  salary: number;
  region: string;
  point: number;
  accountNo: string;
}

// Modal component
const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
        <div className="text-center">{children}</div>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          닫기
        </button>
      </div>
    </div>
  );
};

function UserInfoEditPage() {
  // Initial state is null
  const [userData, setUserData] = useState<UserData | null>(null);
  const [formData, setFormData] = useState<UserData | null>(null); // Separate form data state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch user data
  const fetchUserData = async () => {
    try {
      const response = await getUserInfo();
      setUserData(response); // Update state here
      setFormData(response); // Initialize formData with user data
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleChange = (key: keyof UserData, value: string | number) => {
    if (formData) {
      setFormData({
        ...formData,
        [key]: value,
      });
    }
  };

  const handleSubmit = async () => {
    if (!formData) {
      console.error("User data is not available.");
      return;
    }

    try {
      // Define the request body based on the formData
      const requestBody = {
        name: formData.name || null,
        gender: formData.gender || null,
        password: formData.password || null,
        age: formData.age || null,
        job: formData.job || null,
        salary: formData.salary ? `${formData.salary}` : null,
        region: formData.region || null,
      };

      if (formData.password != "" && formData.password != null) {
        var token = tokenUtils.getToken()

        // Make the PUT request
        const response = await axios.put(
          "https://j11d103.p.ssafy.io/api/users/signUp",
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
    
        // Check for status code 200 (success)
        if (response.status === 202) {
          console.log("성공: 회원 가입이 완료되었습니다.");
          setIsModalOpen(true); // Open the modal on success
        } else {
          console.log("실패: 회원 가입 요청이 실패했습니다.");
        }
      }

      
    } catch (error) {
      console.error("에러 발생:", error);
      console.log("실패: 회원 가입 요청이 실패했습니다.");
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <p className="px-5 py-2 font-bold">내 정보 수정</p>

        {formData ? (
          <>
            <UserInfoEditComponent
              name="이름"
              description={formData.name}
              onChange={(value) => handleChange("name", value)}
            />
            <UserInfoEditComponent
              name="비밀번호"
              description={""}
              onChange={(value) => handleChange("password", value)}
            />
            <UserInfoEditComponent
              name="성별"
              description={formData.gender}
              onChange={(value) => handleChange("gender", value)}
            />
            <UserInfoEditComponent
              name="나이"
              description={formData.age ? formData.age.toString() : "Loading..."}
              onChange={(value) => handleChange("age", Number(value))}
            />
            <UserInfoEditComponent
              name="직업"
              description={formData.job || "Loading..."}
              onChange={(value) => handleChange("job", value)}
            />
            <UserInfoEditComponent
              name="월급"
              description={formData.salary ? `${formData.salary}만원` : "Loading..."}
              onChange={(value) => handleChange("salary", Number(value))}
            />
            <UserInfoEditComponent
              name="사는 지역"
              description={formData.region || "Loading..."}
              onChange={(value) => handleChange("region", value)}
            />
            <div className="px-5 py-3 cursor-pointer hover:bg-gray-100 transition-colors">
              <div className="flex flex-col space-y-2">
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500 py-2 text-white rounded-md hover:bg-blue-600 transition duration-300"
                >
                  수정 완료
                </button>
              </div>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}

        <Modal isOpen={isModalOpen} onClose={() => goBack()}>
          <p className="text-lg font-semibold">수정이 완료되었습니다.</p>
        </Modal>
      </div>
    </div>
  );
}

export default UserInfoEditPage;
