import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../../styles/colors';
import { authApi, checkVerificationCodeQueryParams, sendVerificationCodeQueryParams } from '../../../api/authApi';

const AuthButton = styled(Button)({
  marginTop: '20px',
  width: '100%',
  padding: '10px',
  fontSize: '16px',
  backgroundColor: colors.zozal_green,
  color: 'white',
  '&:hover': {
    backgroundColor: colors.zozal_grey,
  },
});

type SignUpStep1Props = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  onNextStep: () => void;
};

function SignUpStep1({ email, setEmail, onNextStep }: SignUpStep1Props) {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const handleSendVerificationEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    const queryParams: sendVerificationCodeQueryParams = { email };
    try {
      await authApi.sendVerificationCode(queryParams);
      setIsEmailSent(true);
    } catch (error) {
      console.error('Failed to send verification code:', error);
    }
  };

  const handleVerifyEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    const queryParams: checkVerificationCodeQueryParams = {
      email,
      authenticationCode: verificationCode
    };
    try {
      await authApi.checkVerificationCode(queryParams);
      onNextStep();
    } catch (error) {
      console.error('Failed to verify code:', error);
    }
  };

  return (
    <>
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        margin="normal"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={isEmailSent}
      />
      {isEmailSent && (
        <TextField
          label="인증번호"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
      )}
      <AuthButton 
        type="submit" 
        onClick={isEmailSent ? handleVerifyEmail : handleSendVerificationEmail}
      >
        {isEmailSent ? '인증 확인' : '이메일 인증'}
      </AuthButton>
    </>
  );
}

export default SignUpStep1;
