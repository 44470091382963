

import { userApi } from "../../api/userApi";
import AccountRegisterScreen from "../AccountRegister/AccountRegisterScreen";
import SpendingGoalMain from "../SpendingGoal/SpendingGoalMain";
import { CardContainer } from "./components/CardComponents";
import UserInfoComponents from "./components/UserInfoComponents";


function ChangeConsumptionGoalPage() {  
  return (
    <div>
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <SpendingGoalMain/>
        </div>
    </div>
  );
};

export default ChangeConsumptionGoalPage;