import axiosInstance from "../utils/axiosInstance";

export const userApi = {

  fetchUserInfo: async (): Promise<UserInfo | null> => {
    try {
      const response = await axiosInstance.get<UserInfo>('/users/searchUserByEmail');
      console.log('response : ', response);
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  fetchUserAccounts: async (): Promise<BankAccount[]> => {
    try {
      const response = await axiosInstance.get<BankAccount[]>('/banking/searchAccountListByEmail');
      console.log('response : ', response);
      return response.data;
    } catch (error) {
      console.log(error)
      return [];
    }
  },

  setUserAccount: async (queryParams: setUserAccountParams): Promise<boolean> => {
    try {
      const response = await axiosInstance.post('/banking/setAccount', null, {
        params: queryParams
      });
      
      if (response.status === 200) {
        return true;
      } else {
        console.error('소비계좌 등록 실패');
        return false;
      }
    } catch (error) {
      console.error('소비계좌 등록 요청 중 오류 발생', error);
      return false;
    }
  },

  getUserConsumptionPrediction: async (): Promise<number | null> => {
    try {
      const response = await axiosInstance.post<getUserConsumptionPredictionResponse>('/consumption-prediction');
      
      if (response.status === 200) {
        return response.data.success_prob;
      } else {
        console.error('소비계좌 등록 실패');
        return null;
      }
    } catch (error) {
      console.error('소비계좌 등록 요청 중 오류 발생', error);
      return null;
    }
  },
};

export interface UserInfo {
  id: number;
  userKey: string;
  name: string;
  gender: string;
  email: string;
  password: string;
  age: number;
  job: string;
  salary: number;
  region: string;
  point: number;
  accountNo: string | null;
}

export interface setUserAccountParams {
  accountNo: string;
};

export interface BankAccount {
  bankCode: string;
  bankName: string;
  userName: string;
  accountNo: string;
  accountName: string;
  accountTypeCode: string;
  accountTypeName: string;
  accountCreatedDate: string; // YYYYMMDD 형식
  accountExpiryDate: string;  // YYYYMMDD 형식
  dailyTransferLimit: string; // 금액을 문자열로 표현
  oneTimeTransferLimit: string; // 금액을 문자열로 표현
  accountBalance: string; // 금액을 문자열로 표현
  lastTransactionDate: string; // YYYYMMDD 형식 또는 빈 문자열
  currency: string;
}

export interface getUserConsumptionPredictionResponse {
  success_prob: number;
  worst_point: number;
}
