type ToggleTabForGoalProps = {
  activeTab: string;
  onTabChange: (tab: string) => void;
};

function ToggleTabForGoal({ activeTab, onTabChange }: ToggleTabForGoalProps) {
  return (
    <div className="flex w-full rounded-full bg-gray-200 p-1">
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-colors duration-200 ${
          activeTab === 'user'
            ? 'bg-white text-gray-800 shadow'
            : 'text-gray-500 hover:text-gray-700'
        }`}
        onClick={() => onTabChange('user')}
      >
        직접 입력
      </button>
      <button
        className={`flex-1 py-2 px-4 rounded-full text-sm font-medium transition-colors duration-200 ${
          activeTab === 'coach'
            ? 'bg-white text-gray-800 shadow'
            : 'text-gray-500 hover:text-gray-700'
        }`}
        onClick={() => onTabChange('coach')}
      >
        AI코치 추천
      </button>
    </div>
  );
}

export default ToggleTabForGoal;
