import { useEffect, useState } from 'react';
import '../../style/PayResultScreen.css';
import BasicButton from '../../components/BasicButton';

type SpendingGoalsResultScreenProps = {
  onResultDone: () => void;
};

function SpendingGoalResult({onResultDone}: SpendingGoalsResultScreenProps) {
  const [showAnimation, setShowAnimation] = useState(false);
  
  useEffect(() => {
    if (showAnimation) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation]);

  return (
    <div className="flex flex-col h-[calc(100vh-115px)] bg-gray-100">
      <div className="flex-grow flex flex-col items-center justify-center p-6">
        <div className="mb-8">
          <div className="w-24 h-24 rounded-full bg-blue-500 flex items-center justify-center animate-scale">
            <svg className="w-16 h-16 text-white animate-draw" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <p className="text-2xl font-bold text-center mt-4 animate-fade-in">생성 완료!</p>
        </div>
      </div>
      
      {/* 결제 버튼 */}
      <div className="p-6">
        <BasicButton
          onClick={onResultDone}
        >
          <span className="white text-xl font-regular white">확인</span>
        </BasicButton>
      </div>
    </div>
  );
}

export default SpendingGoalResult;
