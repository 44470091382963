import React from 'react';
import { XCircle } from 'lucide-react';

export default function ErrorModal({ isOpen = true, onClose = () => {} }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-10 max-w-[480px] w-full min-h-[360px] flex flex-col justify-center">
        <div className="flex flex-col items-center gap-8">
          <XCircle className="w-32 h-32 text-red-500" />
          <h2 className="text-2xl font-semibold">잘못된 QR코드입니다</h2>
          <button
            onClick={onClose}
            className="w-full bg-gray-500 text-white py-4 px-6 rounded-lg hover:bg-blue-600 transition-colors text-xl font-medium"
          >
            다시 시도
          </button>
        </div>
      </div>
    </div>
  );
}