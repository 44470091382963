import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, styled, Divider, Box } from '@mui/material';
import { colors } from '../styles/colors';

interface PaymentConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  amount: number;
  storeName: string;
  category: string;
  memo: string;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    overflow: 'hidden',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: colors.zozal_green,
  color: 'white',
  padding: theme.spacing(2),
  fontWeight: 'bold',
  margin: 0,
  textAlign: 'center',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3), // 제목과 내용 사이의 간격 추가
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const InfoItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.5),
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

const AmountValue = styled(InfoValue)({
  color: colors.zozal_green,
});

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  '& > *': {
    flex: 1,
    margin: theme.spacing(0, 1, 1, 1),
    borderRadius: theme.shape.borderRadius,
    height: 56,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '1.1rem',
  textTransform: 'none',
}));

const CancelButton = styled(ActionButton)({
  color: colors.zozal_grey,
  backgroundColor: '#f5f5f5',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
});

const ConfirmButton = styled(ActionButton)({
  backgroundColor: colors.zozal_green,
  color: 'white',
  '&:hover': {
    backgroundColor: '#0ea396',
  },
});

const PaymentConfirmationModal: React.FC<PaymentConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  amount,
  storeName,
  category,
  memo
}) => {
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle>결제 확인</StyledDialogTitle>
      <ContentWrapper>
        <StyledDialogContent>
          <InfoItem>
            <InfoLabel variant="subtitle2">결제처</InfoLabel>
            <InfoValue variant="h6">{storeName}</InfoValue>
          </InfoItem>
          <Divider style={{ margin: '16px 0' }} />
          <InfoItem>
            <InfoLabel variant="subtitle2">금액</InfoLabel>
            <AmountValue variant="h5">
              {amount.toLocaleString()}원
            </AmountValue>
          </InfoItem>
          <Divider style={{ margin: '16px 0' }} />
          <InfoItem>
            <InfoLabel variant="subtitle2">카테고리</InfoLabel>
            <InfoValue variant="h6">{category}</InfoValue>
          </InfoItem>
          <Divider style={{ margin: '16px 0' }} />
          <InfoItem>
            <InfoLabel variant="subtitle2">메모</InfoLabel>
            <InfoValue variant="h6">{memo}</InfoValue>
          </InfoItem>
        </StyledDialogContent>
      </ContentWrapper>
      <StyledDialogActions>
        <CancelButton onClick={onClose}>
          취소
        </CancelButton>
        <ConfirmButton onClick={onConfirm}>
          결제하기
        </ConfirmButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default PaymentConfirmationModal;

