import { Route, Routes } from "react-router-dom";
import HomeMain from "./HomeMain";
import HomeAccountDetail from "./HomeAccountDetail";
import HomeTopWorstSpendDetail from "./HomeTopWorstDetail";
import HomeGraph from "./HomeGraph";

function Home() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeMain />} />
        <Route path="/accountDetail" element={<HomeAccountDetail />} />
        <Route path="/accountDetail/successProbabilityDetail" element={<HomeGraph />} />
        <Route path="/accountDetail/topWorstSpendDetail" element={<HomeTopWorstSpendDetail />} />
      </Routes>
    </>
  );
};

export default Home;
