export const formatDate = (date: Date | string | number): string => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatCurrency = (amount: number): string => {
  return amount.toLocaleString('ko-KR');
};

export function formatDateString(dateString: string): string {
  if (dateString.length !== 8) {
    throw new Error('Invalid date string format. Expected YYYYMMDD.');
  }

  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(4, 6));
  const day = parseInt(dateString.substring(6, 8));

  // 월 이름 배열 (0부터 시작하므로 첫 요소는 비워둡니다)
  const monthNames = [
    '', '1월', '2월', '3월', '4월', '5월', '6월',
    '7월', '8월', '9월', '10월', '11월', '12월'
  ];

  return `${monthNames[month]} ${day}일`;
}

export function formatTimeString(timeString: string): string {
  if (timeString.length !== 6) {
    throw new Error('Invalid time string format. Expected HHMMSS.');
  }

  const hours = timeString.substring(0, 2);
  const minutes = timeString.substring(2, 4);

  return `${hours}:${minutes}`;
}

export function getFirstDayOfCurrentMonth(): Date {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1);
}

export const getDayFromDateString = (dateString: string): number => {
  if (dateString.length !== 8) {
    throw new Error('날짜 형식은 YYYYMMDD여야 합니다.');
  }
  
  const day = parseInt(dateString.slice(-2), 10);

  return day;
};

export function convertStringToDate(dateString: string): Date {
  if (!/^\d{8}$/.test(dateString)) {
    throw new Error('잘못된 날짜 형식입니다. YYYYMMDD 형식이어야 합니다.');
  }

  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1; // 월은 0부터 시작
  const day = parseInt(dateString.substring(6, 8), 10);

  const date = new Date(year, month, day);

  // 유효한 날짜인지 확인
  if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
    throw new Error('유효하지 않은 날짜입니다.');
  }

  return date;
}

export function convertTimestampToDate(timestamp: number): Date | null {
  if (isNaN(timestamp)) {
    console.error('유효하지 않은 타임스탬프입니다.');
    return null;
  }

  const date = new Date(timestamp);

  // 유효한 Date 객체인지 확인
  if (isNaN(date.getTime())) {
    console.error('유효하지 않은 Date 객체가 생성되었습니다.');
    return null;
  }

  return date;
}

export function getTimestampFromDate(date: Date): number {
  return date.getTime();
}

function parseDate(dateString: string): Date {
  return new Date(dateString);
}

// '10월 11일' 형식으로 반환하는 함수
export function formatDateToMonthDay(dateString: string): string {
  const date = parseDate(dateString);
  const formatter = new Intl.DateTimeFormat('ko-KR', { month: 'long', day: 'numeric' });
  return formatter.format(date);
}

// '00:57' 형식으로 반환하는 함수
export function formatTimeToHourMinute(dateString: string): string {
  const date = parseDate(dateString);
  return date.toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit', hour12: false });
}

export function parseISODate(dateString: string): Date {
  // 정규 표현식을 사용하여 입력 문자열의 형식 검증
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/;
  if (!isoDateRegex.test(dateString)) {
    throw new Error('유효하지 않은 날짜 형식입니다. ISO 8601 형식이어야 합니다.');
  }

  const date = new Date(dateString);

  // 유효한 Date 객체인지 확인
  if (isNaN(date.getTime())) {
    throw new Error('유효하지 않은 날짜입니다.');
  }

  return date;
}


