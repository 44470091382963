import React, { ReactNode } from "react";
import { Select, MenuItem, SelectChangeEvent, styled } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface SelectorForCategoryProps {
  value: string;
  onChange: (value: string) => void;
}

const categories = [
  { value: 'ALL', label: '전체' },
  { value: 'FOOD', label: '식비' },
  { value: 'CAFE_AND_SNACK', label: '카페/간식' },
  { value: 'LEISURE', label: '취미/여가' },
  { value: 'TRANSPORTATION', label: '교통/통신' },
  { value: 'LIVING', label: '생활' },
];

const StyledSelect = styled(Select)(({ theme }) => ({
  height: '40px',
  width: '120px', // 5글자에 맞는 적절한 너비 설정
  borderRadius: '20px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    paddingLeft: '20px',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  height: '40px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function SelectorForCategory({ value, onChange }: SelectorForCategoryProps) {
  const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    onChange(event.target.value as string);
  };

  return (
    <StyledSelect
      value={value}
      onChange={handleChange}
      displayEmpty
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={{
        PaperProps: {
          sx: {
            width: '120px', // Select와 동일한 너비로 설정
            borderRadius: '10px',
            marginTop: '8px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          },
        },
      }}
    >
      {categories.map((category) => (
        <StyledMenuItem key={category.value} value={category.value}>
          {category.label}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
}
