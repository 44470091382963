import React from 'react';

type UserProfileHeaderProps = {
  name: string;
  email: string;
  onLogout: () => void;
};

function UserProfileHeader({ name, email, onLogout }: UserProfileHeaderProps) {
  return (
    <div className="bg-white p-4 px-6 shadow-lg rounded-lg" onClick={onLogout}>
      <div className="flex items-center justify-between">
      <div className="flex flex-col">
        <h2 className="text-2xl font-bold text-gray-900">사용자님</h2>
        <p className="text-gray-600 text-xs mt-2">내 정보 수정하기</p>
      </div>
        <div className="text-gray-500">
          &gt;
        </div>
      </div>
    </div>
  );
};

export default UserProfileHeader;