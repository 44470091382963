import React from "react";
import { Avatar, Box, Divider, Paper, Stack, Typography, useTheme } from "@mui/material";
import { formatCurrency, formatDateString, formatTimeString } from "../../../utils/formatUtil";
import { SpendingItemType } from "../../../utils/enumUtil";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ChatIcon from '@mui/icons-material/Chat';

type SpendingItemProps = {
  SpendingItemTitle: string;
  SpendingItemDate: string;
  SpendingItemTime: string;
  SpendingAmount: string;
  transactionAfterBalance: string;
  CumulativeSpending: number;
  SuccessAttribution: number;
  SuccessProbability: number;
  SpendingCategory: string;
  ItemType: SpendingItemType;
  Rank?: number;
  FeedbackMessage?: string;
};

function SpendingItem({
  SpendingItemTitle,
  SpendingItemTime,
  SpendingAmount,
  transactionAfterBalance,
  SpendingItemDate,
  SuccessAttribution,
  SuccessProbability,
  ItemType,
  FeedbackMessage,
  SpendingCategory
}: SpendingItemProps) {
  const theme = useTheme();

  const getCategoryText = (category: string) => {
    switch (category) {
      case 'TRANSPORTATION':
        return '교통';
      case 'FOOD':
        return '식비';
      case 'CAFE_AND_SNACK':
        return '간식';
      case 'LEISURE':
        return '여가';
      case 'LIVING':
        return '생활';
      default:
        return '기타';
    }
  };

  return (
    <Paper
      elevation={2}
      sx={{
        margin: '10px 0',
        padding: '20px',
        borderRadius: '10px',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: theme.shadows[4],
        }
      }}
    >
      <Stack spacing={2}>
        <Stack direction='row' justifyContent="space-between" alignItems="flex-start">
          <Stack direction='row' spacing={2} alignItems="center">
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: '#ADB5BD', // 옅은 회색 색상 코드
                fontSize: '0.875rem',
                fontWeight: 'bold'
              }}
            >
              {getCategoryText(SpendingCategory)}
            </Avatar>
            <Stack>
              <Typography variant="h6" fontWeight="bold">{SpendingItemTitle}</Typography>
              <Typography variant="body2" color="text.secondary">
                {`${SpendingItemDate} ${SpendingItemTime}`}
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems="flex-end">
            <Typography
              variant="h6"
              fontWeight="bold"
              color={theme.palette.error.main}
            >
              -{SpendingAmount}원
            </Typography>
            {/* <Typography variant="body2" color="text.secondary">
              잔액 {formatCurrency(parseInt(transactionAfterBalance))}원
            </Typography> */}
          </Stack>
        </Stack>

        <Divider />

        <Stack direction='row' justifyContent="space-between" alignItems="center">
          <Stack direction='row' spacing={3}>
            <Stack direction='row' spacing={1} alignItems="center">
              <ThumbUpAltIcon color="action" />
              <Typography variant="body2" color="text.secondary">
                성공 기여도 <strong>{SuccessAttribution}</strong>
              </Typography>
            </Stack>
            <Stack direction='row' spacing={1} alignItems="center">
              <TrendingUpIcon color="action" />
              <Typography variant="body2" color="text.secondary">
                성공 확률 <strong>{SuccessProbability.toFixed(1)}%</strong>
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              bgcolor: theme.palette.primary.main,
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
            }}
          >
            A
          </Box>
        </Stack>

        {ItemType !== SpendingItemType.Plain && FeedbackMessage && (
          <Paper
            sx={{
              bgcolor: theme.palette.grey[50],
              padding: '12px',
              borderRadius: '8px',
              border: `1px solid ${theme.palette.grey[200]}`,
            }}
          >
            <Stack direction='row' spacing={2} alignItems="center">
              <ChatIcon color="primary" />
              <Typography variant="body2">
                <strong>AI 피드백:</strong> {FeedbackMessage}
              </Typography>
            </Stack>
          </Paper>
        )}
      </Stack>
    </Paper>
  );
}

export default SpendingItem;
