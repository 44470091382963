

function PersonalInformationConsentPage() {  
  return (
    <div>
      <div className="p-5 bg-white shadow-lg rounded-lg overflow-hidden">
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem' }}>개인정보 처리방침</h1>    <br/>
        <div>
        주식회사 조잘조잘(이하 조잘)은 관련  법령*에 따라 이용자분들의 개인(신용)정보(이하  개인정보)를 안전하게 처리하며 보호하고  있어요. 조잘은 개인정보 처리방침을 공개하여  이용자의 고충을 원활하게 처리할 수 있도록  하며, 개인정보를 안전하게 관리하고 있다는  것을 안내하고 있어요.     <br/>
        *관련 법령: [개인정보보호법], 신용정보법],  r전자금융거래법, 전자상거래법],  r전자서명법J, r정보통신망법.등     <br/>   <br/>
        지금부터 조잘의 개인정보 처리방침에 관해  설명해드릴게요. 목차는 다음과 같아요. <br/>   <br/>
        ① 개인정보 수집과 이용   <br/>
        ② 제3자 제공및 위탁/ 국외이전   <br/>
        ③ 보유기간 및 파기   <br/>
        ④ 개인정보의 안전성 확보조치   <br/>
        ⑤ 개인위치정보에 관한 처리   <br/>
        ⑥ 정보주체와 법정대리인의 권리.의무 행사 방법   <br/>
        ⑦ 개인정보보호책임자 및 고충처리 담당부서   <br/>
        ⑧ 개인정보 처리방침 시행일 및 변경 사항   <br/>
        </div>
        <br/><br/>
        <h2 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem' }}>① 개인정보 수집과 이용</h2> 
        <div>
        아낌나무는 이용자의 동의를 받아 아낌나무 서비스 이용에 반드시 필요한 최소한의 정보를 수집하고 있어요. 
        서비스 이용을 위해 반드시 필요한 항목은 필수 동의로,그 외에는 선택 동의로 나누어 동의받고 있어요. <br/>
        아낌나무는 수집한 항목을 목적 외의 용도로는 이용하고 있지 않으며, 
        처리 항목과 목적이 변경될 시 개인정보 처리방침을 지속적으로 업데이트하고 있어요. <br/>
        
        동의를 했더라도 동의를 철회하고 싶다면 설정 메뉴(전체 메뉴&lt 후설정 &lt 약관 및 개인정보 처리 동의)에서 언제든지 철회할 수 있으니 참고해주세요.
        (다만, 송금과 결제 등 일부 필수적 서비스에 대해서는 취소 기능이 제공되지 않을 수 있어요.)
        <br/><br/><br/>
        아낌나무에서 개인정보를 수집하여 처리하는 방법은 크게 5가지로 볼 수 있어요
        <br/><br/>

        º 아낌나무 앱 내에서 수집한 정보 <br/>

        아낌나무 앱 내에서 개인정보를 수집하는 형태는 다음과 같아요<br/>
        1. 이용자가 아낌나무 서비스에 직접 입력한 정보<br/>
        2. 이용자가 아낌나무 앱의 조회 서비스를 통해 조회한 정보<br/>

        <br/>
        </div>

        <div className="bg-gray-200 rounded-lg p-4">
          회원가입 시에는 이름, 생년월일, 성별, 휴대전화번호, 금융기관명/계좌번호 예금주명, 이메일주소, 
          CI(연계정보), DI(중복가입확인정보), 내/외국인 여부, 국적 고객식별값이 처리 돼요. 회원가입 시 개인정보를 처리하는 목적은 다음과 같아요. <br/>
          
          • 본인확인, 연령확인, 계좌정보확인 <br/>
          • 금융거래 관련 본인인증 및 금융서비스 (송금, 결제, 적립) 제공 <br/>
          • 민원처리, 분쟁해결, 법령상 의무이행을 위한 의사소통 경로 확보 <br/>
          • 서비스 관련 공지사항 전달, 고객만족도 조사 <br/>
          • 서비스 이용현황 통계분석 및 활용 <br/>
          • 서비스 제공 대상자 선정 <br/>
          • 신규서비스 이용 시 또는 본인인증 시, 보유정보를 이용한 입력편의성 제공 <br/>
          • 부정이용방지(금융사기 방지, 이상거래 탐지를 효율적으로 관리하기 위한 목적, 부정거래에 대한 조회/확인, 보이스피싱 관련 조회/확인, 리워드 중복 수령 방지 및 어뷰징 방지), 사고조사, 보안정책 수립
        </div>

        <br/>
        
        💡 자세한 사항은 아낌나무 앱 서비스 별로 수집한 개인정보를 클릭하여 확인해주세요. <br/><br/>

        • 상담 및 민원 처리 과정에서 수집한 정보 <br/>
        아낌나무 고객센터로 피해 사고를 신고하거나 민원 접수 과정에서도 개인정보를 수집하고 있어요.<br/><br/>

        💡 자세한 사항은 상담 및 민원 처리 과정에서 수집된 개인정보를 클릭하여 확인해주세요

        <div>
        </div>
        <br/>
      </div>
    </div>
  );
};

export default PersonalInformationConsentPage;