import { Button, styled } from "@mui/material";
import { formatCurrency } from "../../../utils/formatUtil";
import { colors } from "../../../styles/colors";
import bankLogoImage from '../../../assets/kakao_logo.png';

type AccountBoxProps = {
  balance: number,
  onNavigate: () => void,
  onDismiss: () => void,
};

const StyledButton = styled(Button)({
  padding: '8px 16px',
  fontSize: '14px',
  backgroundColor: '#6b7280',
  color: 'white',
  whiteSpace: 'nowrap',
  minWidth: 'auto',
  '&:hover': {
    backgroundColor: colors.zozal_grey,
  },
});

function AccountOverview({ balance, onNavigate }: AccountBoxProps) {
  return (
    <div className="flex items-center justify-between px-2 py-4"> {/* 가로 패딩 감소 */}
      <div className="flex items-center">
        <img
          src={bankLogoImage}
          alt="Bank Logo"
          className="w-10 h-10 rounded-full"
        />
        <span className="ml-3 text-gray-800 text-lg font-bold"> {/* 마진과 폰트 크기 조정 */}
          {formatCurrency(balance)}원
        </span>
      </div>
      <StyledButton
        onClick={onNavigate}
        className="ml-2"
      >
        내역 보기
      </StyledButton>
    </div>
  );
};

export default AccountOverview;
