import React, { useState, useEffect, useReducer } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css'
import SplashScreen from './pages/Splash/SplashScreen';
import AuthScreen from './pages/Auth/AuthScreen';
import AccountRegisterScreen from './pages/AccountRegister/AccountRegisterScreen';
import { authApi } from './api/authApi';
import { tokenUtils } from './utils/tokenUtil';
import { userApi } from './api/userApi';
import TopBar from './components/TopBar';
import BottomNav from './components/BottomNav';
import Mission from './pages/Mission/Mission';
import AnalysisContainer from './pages/Analysis/AnalysisContatiner';
import MyPage from './pages/MyPage/MyPage';
import Pay from './pages/QrPay/Pay';
import Home from './pages/Home/Home';
import SpendingGoal from './pages/SpendingGoal/SpendingGoal';
import { createTheme, ThemeProvider } from '@mui/material';
import NewAiCoach from './pages/AiCoach/NewAiCoach';

const initialState = {
  authenticated: false,
  token: null
}

interface TokenState {
  token: string | null;
  authenticated: boolean;
}

interface Action {
  type: 'SET_TOKEN';
  token: string | null;
  result: boolean;
}

const reducer = (state: TokenState, action: Action): TokenState => {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.token, authenticated: action.result };
    default:
      return state;
  }
};

const hideNavigationRoutes = ['/Auth', '/AccountRegister', '/Splash',
  '/Pay/qrScan', '/Pay', '/Pay/', '/Pay/scanResult', '/Pay/payResult',
  '/SpendingGoal', '/SpendingGoal/spendingGoalDetail', '/SpendingGoal/spendingGoalResult'];

const theme = createTheme({
  typography: {
    fontFamily: 'Toss Product Sans',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            fontFamily: 'Toss Product Sans',
          },
          '& .MuiInputLabel-root': {
            fontFamily: 'Toss Product Sans',
          },
        },
      },
    },
  },
});

function App() {
  const [showSplash, setShowSplash] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAccountRegistered, setIsAccountRegistered] = useState(false);
  const [tokenState, dispatch] = useReducer(reducer, initialState);
  const { authenticated } = tokenState;
  const [showNavigation, setShowNavigation] = useState(true);

  // Custom hook to get current location
  const useCurrentLocation = () => {
    const location = useLocation();
    console.log(location.pathname);
    useEffect(() => {
      setShowNavigation(!hideNavigationRoutes.includes(location.pathname));
    }, [location]);
  };

  async function handleSignIn(email: string, password: string) {
    const loginData = {
      email,
      password,
    };

    let loginResponse = await authApi.signIn(loginData);

    if (loginResponse) {
      const token = loginResponse.accessToken;
      tokenUtils.setToken(loginResponse.accessToken);
      dispatch({
        type: 'SET_TOKEN',
        token: token,
        result: true,
      });

      const fetchData = async () => {
        try {
          const userInfo = await userApi.fetchUserInfo();
          if (userInfo) {
            if (userInfo.accountNo != null) {
              console.log("userInfo.accountNo:", userInfo.accountNo);
              setIsAccountRegistered(true);
            }
          }
        } catch (e) {
          console.error("사용자 정보 패치 실패");
        } finally {
        }
      };

      fetchData();
      setIsLoggedIn(true);

    } else {
      dispatch({
        type: 'SET_TOKEN',
        token: null,
        result: false,
      });
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (showSplash) {
    return <SplashScreen />;
  }

  if (!isLoggedIn) {
    return (
      <ThemeProvider theme={theme}>
        <AuthScreen trySignIn={handleSignIn} />;
      </ThemeProvider>
    )
  }

  if (isLoggedIn && !isAccountRegistered) {
    return (
      <ThemeProvider theme={theme}>
        <AccountRegisterScreen onAccountSelected={() => setIsAccountRegistered(true)} />;
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Router>
          <AppContent showNavigation={showNavigation} useCurrentLocation={useCurrentLocation} />
        </Router>
      </div>
    </ThemeProvider>
  );
}

const AppContent = ({ showNavigation, useCurrentLocation }: { showNavigation: boolean, useCurrentLocation: () => void }) => {
  useCurrentLocation();

  return (
    <>
      {showNavigation && <TopBar />}
      <div className="pt-16" style={{ paddingBottom: showNavigation ? '60px' : '0' }}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/Home" />} />
          <Route path="/Home/*" element={<Home />} />
          <Route path="/Mission/*" element={<Mission />} />
          <Route path="/AiCoach/*" element={<NewAiCoach />} />
          <Route path="/Analysis/*" element={<AnalysisContainer />} />
          <Route path="/MyPage/*" element={<MyPage />} />
          <Route path="/Pay/*" element={<Pay />} />
          <Route path="/SpendingGoal/*" element={<SpendingGoal />} />
        </Routes>
        {showNavigation && <BottomNav />}
      </div>
    </>
  );
};

export default App;
