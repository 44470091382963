import React, { useState, useEffect } from 'react';
import { payApi, postConsumptionPredictionParams, postPayRequestParams } from '../../api/payApi';
import { useLocation } from 'react-router-dom';
import BasicButton from '../../components/BasicButton';
import PaymentConfirmationModal from '../../components/PaymentConfirmationModal';
import { bankingApi } from '../../api/bankingApi';
import { CircularProgress } from '@mui/material';

type PayDetailScreenProps = {
  onPaySuccess: () => void;
};

function PayDetailScreen({ onPaySuccess }: PayDetailScreenProps) {
  const [amount, setAmount] = useState<number>(0);
  const [category, setCategory] = useState<string>('');
  const [memo, setMemo] = useState<string>('');
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [isAmountExceeded, setIsAmountExceeded] = useState<boolean>(false);
  const [accountBalance, setAccountBalance] = useState<number>(1000000);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const location = useLocation();
  const data = location.state as { storeId: number; storeName: string };

  useEffect(() => {
    FetchAccountBalance();
  }, []);

  useEffect(() => {
    setIsButtonActive(amount > 0 && category !== '' && memo !== '' && amount <= accountBalance);
    setIsAmountExceeded(amount > accountBalance);
  }, [amount, category, memo, accountBalance]);

  const FetchAccountBalance = async () => {
    try {
      const userAccountBalance = await bankingApi.getAccountBalance();
      if (userAccountBalance != null) {
        setAccountBalance(userAccountBalance.balance);
      }
    } catch (error) {
      console.error('계좌 잔액을 불러오는 데 실패했습니다:', error);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    const numberValue = parseInt(inputValue, 10);
    setAmount(isNaN(numberValue) ? 0 : numberValue);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmPayment = async () => {
    setIsLoading(true);
    setIsModalOpen(false);
    const requestParam: postPayRequestParams = {
      balance: amount,
      title: memo,
      category: category
    };

    try {
      const result = await payApi.postPayRequest(requestParam);
      if (result != null) {
        const predictParam: postConsumptionPredictionParams = {
          id: result.historyId
        };
        const predictionResult = await payApi.postConsumptionPrediction(predictParam);

        if (predictionResult == true) {
          onPaySuccess();
        }
      }
    } catch (error) {
      console.error('Failed to send payment request:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-115px)] bg-gray-100 p-4 space-y-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center space-x-4">
          <div className="w-16 h-16 bg-[#11C2B0] rounded-full flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
            </svg>
          </div>
          <div>
            <h2 className="text-2xl font-bold">{data.storeName}</h2>
            <p className="text-lg text-gray-600">결제처</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-2xl font-bold text-gray-800 mb-6">결제 상세</h3>

        <div className="space-y-6">
          <div className={`bg-[#E6F7F5] p-4 rounded-lg`}>
            <div className="flex justify-between items-center mb-2">
              <label className="block text-lg font-semibold text-gray-700">결제 금액</label>
              <span className="text-sm text-gray-600">잔액: {accountBalance.toLocaleString()}원</span>
            </div>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                value={amount ? amount.toLocaleString() : ''}
                onChange={handleAmountChange}
                placeholder="금액을 입력하세요"
                className={`block w-full text-xl py-3 px-4 rounded-md bg-white border ${isAmountExceeded ? 'border-red-500' : 'border-[#11C2B0]'
                  } focus:ring-2 focus:ring-[#11C2B0] focus:border-transparent`}
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 text-xl">원</span>
              </div>
            </div>
            {isAmountExceeded && <p className="mt-1 text-sm text-red-600">결제 금액이 잔액보다 큽니다</p>}
          </div>

          <div className="bg-[#E6F7F5] p-4 rounded-lg">
            <label className="block text-lg font-semibold text-gray-700 mb-2">소비 카테고리</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="block w-full text-xl py-3 px-4 rounded-md bg-white border border-[#11C2B0] focus:ring-2 focus:ring-[#11C2B0] focus:border-transparent"
            >
              <option value="">카테고리 선택</option>
              <option value="FOOD">식비</option>
              <option value="TRANSPORTATION">교통/통신</option>
              <option value="CAFE_AND_SNACK">카페/간식</option>
              <option value="LEISURE">취미/여가</option>
              <option value="LIVING">생활</option>
            </select>
          </div>

          <div className="bg-[#E6F7F5] p-4 rounded-lg">
            <label className="block text-lg font-semibold text-gray-700 mb-2">메모</label>
            <input
              type="text"
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
              placeholder="결제에 대한 메모를 입력하세요"
              className="block w-full text-xl py-3 px-4 rounded-md bg-white border border-[#11C2B0] focus:ring-2 focus:ring-[#11C2B0] focus:border-transparent"
            />
          </div>
        </div>
      </div>

      <div className="flex-1"></div>

      <BasicButton
        onClick={handleOpenModal}
        disabled={!isButtonActive}
        className={`w-full py-4 text-xl font-bold text-white rounded-lg ${isButtonActive ? 'bg-[#11C2B0] hover:bg-[#0EA396]' : 'bg-[#A4A4A4] cursor-not-allowed'
          }`}
      >
        결제하기
      </BasicButton>

      <PaymentConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmPayment}
        amount={amount}
        storeName={data.storeName}
        category={category}
        memo={memo}
      />

      {isLoading && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-5 rounded-lg flex items-center space-x-3">
            <CircularProgress size={30} />
            <span className="text-lg font-semibold">결제 중...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayDetailScreen;
