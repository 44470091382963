import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import '../../../style/box.css'; 
import { useLocation } from 'react-router-dom';
import { getMbtiData } from '../../../api/get'; 
import { CircularProgress } from '@mui/material';


const Cafe: React.FC = () => {
  const navigate = useNavigate();

  // 동그라미 클릭 시 카테고리별 경로로 이동하는 함수
  const handleCircleClick = (page: string, consumptionDetailId: number, item: any) => {
    console.log("Navigating with consumptionDetailId:", consumptionDetailId);
    navigate(`/Analysis/cafe/${page}`, { state: { data: { consumptionDetailId, item, ...data } } });
  };

  const location = useLocation();
  const { data } = location.state;
  const consumptionTypeId = data.consumptionTypeId;

  const [mbtiData, setMbtiData] = useState<any>(null);

  useEffect(() => {
    const fetchMbtiData = async () => {
      try {
        const response = await getMbtiData(consumptionTypeId);
        setMbtiData(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMbtiData();
  }, [consumptionTypeId]);

  // 데이터가 없을 경우 로딩 상태 처리
  if (!mbtiData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <div style={{ margin: '30px'}}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <p style={{fontSize:'17px'}}>
          사용자님의 유형은
          <br />
          <div style={{ display: 'inline', fontSize: '20px', fontWeight: '700' }}>
            [{data.myType}]
          </div>
          <div style={{ display: 'inline', fontSize: '17px',marginLeft: '0.2em' }}>
            입니다
          </div>
        </p>
        <div>
          <img src={data.imgUrl} alt="image" style={{width: '150px', height: '150px'}}/>
        </div>
      </div>
      </div>
      <div style={{ backgroundColor:'white',padding:'20px'}}>
      <h3 style={{fontWeight: '700',fontSize:'23px',lineHeight:'28px',marginBottom:'15px'
      }}>카페/간식 세부</h3>
      <div>
        {mbtiData.list.map((item: any) => (
          <Box
            key={item.consumptionDetailId}
            component="section"
            className="custom-box"
            onClick={() => handleCircleClick(item.consumptionDetailId.toString(), item.consumptionDetailId, item)}
          >
            <Box className="content-box">
              <h3 style={{ fontSize: '18px', margin: '5px 0', lineHeight: 1,fontWeight:'500' }}>{item.name}</h3>
              <p style={{ fontSize: '14px', margin: '5px 0', lineHeight: 1,color:'#8F8F8F' }}>{item.description}</p>
            </Box>
            <Box className="status-box rounded-left" sx={{backgroundColor:'#FFCB12'}}>
              <p style={{ fontSize: '17px',color:'white',fontWeight:'600' }}>{item.type}</p>
            </Box>
          </Box>
        ))}
      </div>
    </div>

      <div style={{ textAlign: 'center', marginTop: '40px',marginBottom:'40px'  }}>
        <span style={{ fontWeight: 'bold' }}>세부 분석 결과와 추천 상품</span>을
        <br />
        확인해 보세요!
      </div>
    </div>
  );
};

export default Cafe;
