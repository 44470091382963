import { Paper, Stack, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SpendingItem from "./components/SpendingItem";
import { SpendingItemType } from "../../utils/enumUtil";
import { bankingApi, TransactionItem } from "../../api/bankingApi";
import { useEffect, useState } from "react";
import { formatCurrency, formatDateToMonthDay, formatTimeToHourMinute } from "../../utils/formatUtil";
import SelectorForCategory from "../../components/SelectorForCategory";
import { ReceiptLong, Tram } from '@mui/icons-material';

function HomeAccountDetail() {
  const navigate = useNavigate();
  const [accountBalance, setAccountBalance] = useState(0);
  const [transActions, setTransActions] = useState<TransactionItem[]>([]);
  const [category, setCategory] = useState('ALL');

  const FetchAccountBalance = async () => {
    try {
      const userAccountBalance = await bankingApi.getAccountBalance();
      if (userAccountBalance != null) {
        setAccountBalance(userAccountBalance.balance);
      }
    } catch (error) {
      console.error('계좌 잔액을 불러오는 데 실패했습니다:', error);
    }
  };

  const FetchTransactions = async () => {
    try {
      const userTransaction = await bankingApi.getTransactionList();
      if (userTransaction != null) {
        setTransActions(userTransaction);
      }
    } catch (error) {
      console.error('계좌 잔액을 불러오는 데 실패했습니다:', error);
    }
  };

  useEffect(() => {
    FetchAccountBalance();
    FetchTransactions();
  }, []);

  const filteredTransactions = transActions.filter(transaction =>
    category === 'ALL' || transaction.type === category
  );

  return (
    <div style={{ background: '#f5f5f5', minHeight: '100vh' }}>
      <Paper elevation={1} sx={{ margin: '15px', padding: '20px', borderRadius: '10px' }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontSize: '1rem',
            color: 'text.secondary',
            opacity: 0.8
          }}
        >
          사용자님의 소비계좌
        </Typography>
        <Typography
          variant="h2"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: '2rem',
            paddingY: '10px',
            paddingX: '5px'
          }}
        >
          {formatCurrency(accountBalance)}원
        </Typography>
        <div className="w-full flex space-x-2">
          <button
            className="flex-1 bg-gray-500 text-white py-2 px-4 rounded"
            onClick={() => navigate('successProbabilityDetail')}
          >
            소비 성공 확률
          </button>
          <button
            className="flex-1 bg-gray-500 text-white py-2 px-4 rounded"
            onClick={() => navigate('topWorstSpendDetail')}
          >
            소비 하이라이트
          </button>
        </div>
      </Paper>
      <Box sx={{ marginX: '15px', marginTop: '25px', marginBottom: '10px' }}>
        <SelectorForCategory
          value={category}
          onChange={(newValue) => setCategory(newValue)}
        />
      </Box>
      {filteredTransactions.length > 0 ? (
        <Stack direction='column' sx={{ margin: '20px' }}>
          {filteredTransactions.map((transAction) => (
            <SpendingItem
              key={transAction.id}
              SpendingItemTitle={transAction.description}
              SpendingItemTime={formatTimeToHourMinute(transAction.consumptionTime)}
              SpendingItemDate={formatDateToMonthDay(transAction.consumptionTime)}
              SpendingAmount={formatCurrency(transAction.amount)}
              SpendingCategory={transAction.type}
              CumulativeSpending={0}
              SuccessAttribution={transAction.successContribution}
              SuccessProbability={transAction.successProbability}
              ItemType={SpendingItemType.Plain}
              transactionAfterBalance={'0'}
            />
          ))}
        </Stack>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30vh',
            m: 2,
          }}
        >
          <ReceiptLong sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="h6" color="text.secondary" align="center" gutterBottom>
            소비 내역이 없습니다.
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            새로운 소비가 생성되면 여기에 표시됩니다.
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default HomeAccountDetail;
