import React from 'react';
import { Modal, Box, Typography, Card, CardContent, Button } from '@mui/material';
import { postLastMisson, addPoint } from '../../api/post';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import BasicButton from '../../components/BasicButton';

interface Mission {
  id: string;
  mission: {
    title: string;
  };
  isComplete: boolean;
}

interface LastWeekMissions {
  userMissionList: Mission[];
  successCount: number;
  failureCount: number;
}

interface LastWeekMissionsModalProps {
  open: boolean;
  onClose: () => void;
  lastWeekMissions: LastWeekMissions | null;
  handleAddPoints: (successCount: number) => Promise<void>;
  refreshMissionData: () => Promise<void>;
}

const LastWeekMissionsModal: React.FC<LastWeekMissionsModalProps> = ({
  open,
  onClose,
  lastWeekMissions,
  refreshMissionData,
  handleAddPoints
}) => {
  const handleStartMission = async () => {
    try {
      await postLastMisson();
      
      const successCount = lastWeekMissions?.successCount || 0;
      await handleAddPoints(successCount); // 포인트 추가 함수 호출
      
      alert('일주일 미션이 시작되었습니다!');
      await refreshMissionData();
      onClose();
    } catch (error) {
      console.error("미션 시작 실패:", error);
      alert('미션 시작에 실패했습니다.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={styles.modal}>
      <Typography id="modal-title" variant="h6" component="h2" sx={styles.title}>
        {lastWeekMissions && lastWeekMissions.userMissionList?.length > 0 
            ? '이전 미션 리스트' 
            : '지난주 미션이 없습니다.'}
      </Typography>
        {lastWeekMissions && lastWeekMissions.userMissionList?.length > 0 ? (
          <>
            <Box sx={styles.missionList}>
              {lastWeekMissions.userMissionList?.map((mission, index) => (
                <Card key={mission.id} sx={styles.missionCard}>
                  <CardContent>
                    <Typography variant="subtitle1">미션 {index + 1}: {mission.mission.title}</Typography>
                    <Typography 
                      variant="body2" 
                      color={mission.isComplete ? "success.main" : "error.main"}
                    >
                      상태: {mission.isComplete ? '완료' : '미완료'}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
            <Box sx={styles.summary}>
              <Typography variant="body2" sx={{fontWeight:'bold'}}>⭕ 성공: {lastWeekMissions.successCount}</Typography>
              <Typography variant="body2" sx={{fontWeight:'bold'}}>❌ 실패: {lastWeekMissions.failureCount}</Typography>
            </Box>
          </>
        ) : (
        //   <Box sx={styles.noMissions}>
        //     <SentimentDissatisfiedIcon sx={{ fontSize: 80, color: 'gray' }} />
        //     <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        //         새로운 미션을 추가해보세요!
        //     </Typography>
        //   </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SentimentDissatisfiedIcon sx={{ fontSize: 80, color: 'gray' }} />
            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, mb:3 }}>
                새로운 미션을 추가해보세요!
            </Typography>
            </Box>
        )}
        <BasicButton variant="contained" onClick={handleStartMission} sx={styles.button}>
          미션 시작
        </BasicButton>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  },
  title: {
    mb: 2,
    textAlign: 'center',
  },
  missionList: {
    maxHeight: 200,
    overflow: 'auto',
    mb: 2,
  },
  missionCard: {
    mb: 1,
  },
  summary: {
    display: 'flex', 
    justifyContent: 'center', 
    gap: 2
  },
  noMissions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    mb: 3,
  },
  button: {
    width: '100%',
  },
};

export default LastWeekMissionsModal;