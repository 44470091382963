import React from 'react';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../../styles/colors';

const AuthButton = styled(Button)({
  marginTop: '20px',
  width: '100%',
  padding: '10px',
  fontSize: '16px',
  backgroundColor: colors.zozal_green,
  color: 'white',
  '&:hover': {
    backgroundColor: colors.zozal_grey,
  },
});

type SignUpStep3Props = {
  userData: {
    location: string;
    job: string;
    income: string;
  };
  setUserData: React.Dispatch<React.SetStateAction<any>>;
  onNextStep: () => void;
};

const cities = [
  '서울특별시', '부산광역시', '대구광역시', '인천광역시', '광주광역시', '대전광역시', '울산광역시', '세종특별자치시', 
                    '경기도', '강원도', '충청북도', '충청남도', '전라북도', '전라남도', '경상북도', '경상남도', '제주특별자치도'];
const jobs = [	
  '개발자', '디자이너', '기획자', '마케터', '의사', '변호사', '교사', '연구원', '공무원', '자영업자', '프리랜서', '학생', '기타'];

function SignUpStep3({ userData, setUserData, onNextStep }: SignUpStep3Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setUserData((prevData: any) => ({ ...prevData, [name as string]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onNextStep();
  };

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel>거주 지역</InputLabel>
        <Select
          value={userData.location}
          onChange={handleSelectChange}
          name="location"
          label="거주 지역"
          required
        >
          {cities.map((city) => (
            <MenuItem key={city} value={city}>{city}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>직업</InputLabel>
        <Select
          value={userData.job}
          onChange={handleSelectChange}
          name="job"
          label="직업"
          required
        >
          {jobs.map((job) => (
            <MenuItem key={job} value={job}>{job}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="월 소득 (만원)"
        variant="outlined"
        fullWidth
        margin="normal"
        required
        name="income"
        type="number"
        value={userData.income}
        onChange={handleChange}
      />
      <AuthButton type="submit" onClick={handleSubmit}>
        다음
      </AuthButton>
    </>
  );
}

export default SignUpStep3;
