import React from "react";
import { Route, Routes, useNavigate, useLocation, NavigateFunction } from "react-router-dom";
import PayMainScreen from "./PayMainScreen";
import QrScanScreen from "./QrScanScreen";
import PayDetailScreen from "./PayDetailScreen";
import PayResultScreen from "./PayResultScreen";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

interface HeaderProps {
  navigate: NavigateFunction;
}

// Header 컴포넌트
function Header({ navigate }: HeaderProps) {
  const location = useLocation();

  const handleClose = () => {
    navigate('/Home', { replace: true });
  };

  const isQrScanScreen = location.pathname === '/Pay/qrScan';

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#f0f0f0',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000
    }}>
      {isQrScanScreen && (
        <IconButton onClick={() => navigate(-1)} size="small">
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      )}
      {!isQrScanScreen && <div style={{ width: '24px' }}></div>}
      <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>결제하기</div>
      {!isQrScanScreen && (
        <IconButton onClick={handleClose} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};

// PayLayout 컴포넌트를 위한 props 인터페이스
interface PayLayoutProps {
  children: React.ReactNode;
}

// PayLayout 컴포넌트
function PayLayout({ children }: PayLayoutProps) {
  const navigate = useNavigate();

  return (
    <div style={{ paddingTop: '50px' }}>
      <Header navigate={navigate} />
      {children}
    </div>
  );
};

function Pay() {
  const navigate = useNavigate();

  return (
    <PayLayout>
      <Routes>
        <Route path="/" element={<PayMainScreen onClickQrScan={() => navigate('qrScan')} />} />
        <Route path="/qrScan" element={<QrScanScreen />} />
        <Route path="/scanResult" element={<PayDetailScreen onPaySuccess={() => navigate('payResult')} />} />
        <Route path="/payResult" element={<PayResultScreen onResultDone={() => navigate('/Home', { replace: true })} />} />
      </Routes>
    </PayLayout>
  );
};

export default Pay;
