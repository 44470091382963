import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Paper, TextField, InputAdornment, useTheme, Card, CardContent, Divider } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { postUserConsumptionGoalsParams, spendGoalApi } from '../../api/spendGoalApi';
import { useNavigate } from 'react-router-dom';
import BasicButton from '../../components/BasicButton';
import ToggleTabForGoal from "../../components/ToggleTabForGoal";

type ExpenseCategory = 'food' | 'living' | 'leisure' | 'transportation' | 'cafeAndSnack';
type TabType = 'user' | 'coach';

interface Expense {
  [key: string]: number;
}

interface ExpenseCategoryConfig {
  label: string;
  key: ExpenseCategory;
  color: string;
}

// ToggleTabForGoal의 props 타입 정의
interface ToggleTabForGoalProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const EXPENSE_CATEGORIES: ExpenseCategoryConfig[] = [
  { label: "식비", key: "food", color: "#FF6B6B" },
  { label: "생활", key: "living", color: "#4ECDC4" },
  { label: "취미/여가", key: "leisure", color: "#45B7D1" },
  { label: "교통/통신", key: "transportation", color: "#F7B731" },
  { label: "카페/간식", key: "cafeAndSnack", color: "#25CCF7" },
];

function SpendingGoalMain() {
  const navigate = useNavigate();
  const [expenses, setExpenses] = useState<Expense>({
    food: 300000,
    living: 500000,
    leisure: 200000,
    transportation: 150000,
    cafeAndSnack: 100000,
  });
  const [activeTab, setActiveTab] = useState<TabType>('user');

  const updateExpensesBasedOnTab = (tab: TabType) => {
    let newExpenses: Expense;
    switch (tab) {
      case 'user':
        newExpenses = {
          food: 10000,
          living: 10000,
          leisure: 10000,
          transportation: 10000,
          cafeAndSnack: 10000,
        };
        break;
      case 'coach':
        newExpenses = {
          food: 450000,
          living: 220000,
          leisure: 320000,
          transportation: 50000,
          cafeAndSnack: 100000,
        };
        break;
      default:
        // 'same' 탭이나 다른 경우에는 현재 상태 유지
        return;
    }
    setExpenses(newExpenses);
  };

  useEffect(() => {
    updateExpensesBasedOnTab(activeTab);
  }, [activeTab]);


  const totalExpense = Object.values(expenses).reduce((sum, value) => sum + value, 0);

  const handleExpenseChange = (key: ExpenseCategory, value: string) => {
    const numValue = value === '' ? 0 : parseInt(value.replace(/,/g, ''), 10);
    setExpenses(prev => ({ ...prev, [key]: isNaN(numValue) ? prev[key] : numValue }));
  };

  const handlePostUserGoals = async () => {
    const postUserGoalsBodyParams: postUserConsumptionGoalsParams = {
      foodGoal: expenses.food,
      livingGoal: expenses.living,
      transportationGoal: expenses.transportation,
      cafeAndsnackGoal: expenses.cafeAndSnack,
      leisureGoal: expenses.leisure
    };

    try {
      const result = await spendGoalApi.postUserConsumptionGoals(postUserGoalsBodyParams);
      if (result != null) {
        navigate('spendingGoalResult');
      } else {
        console.log('Failed to set spending goals');
      }
    } catch (error) {
      console.error('Error posting user goals:', error);
    }
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as TabType);
  };

  const chartData = EXPENSE_CATEGORIES.map(category => ({
    name: category.label,
    value: expenses[category.key],
  }));

  // 현재 월을 가져오는 함수
  const getCurrentMonth = () => {
    const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
    return months[new Date().getMonth()];
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 3, pl: 2 }}>
        {getCurrentMonth()} 소비 목표
      </Typography>
      
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        width: '100%', 
        mb: 3,
        '& > div': { width: '100%' }
      }}>
        <ToggleTabForGoal 
          activeTab={activeTab} 
          onTabChange={handleTabChange}
        />
      </Box>

      <Card elevation={3} sx={{ mt: 3, mb: 4, borderRadius: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
            <Box sx={{ width: '60%', height: 150 }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={35}
                    outerRadius={70}
                    dataKey="value"
                    strokeWidth={2}
                  >
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={EXPENSE_CATEGORIES[index].color} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Box>
            <Box sx={{ width: '55%', textAlign: 'right' }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                총 소비 목표 금액
              </Typography>
              <Typography variant="h5" fontWeight="bold" color="primary">
                {totalExpense.toLocaleString()}원
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          {EXPENSE_CATEGORIES.map(({ label, key, color }, index) => (
            <React.Fragment key={key}>
              <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                <Box sx={{ width: 16, height: 16, borderRadius: '50%', bgcolor: color, mr: 2 }} />
                <Typography variant="body2" sx={{ flexGrow: 1 }}>{label}</Typography>
                <TextField
                  value={expenses[key].toLocaleString()}
                  onChange={(e) => handleExpenseChange(key, e.target.value)}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">원</InputAdornment>,
                  }}
                  sx={{ width: '120px' }}
                />
              </Box>
              {index < EXPENSE_CATEGORIES.length - 1 && <Divider sx={{ my: 1 }} />}
            </React.Fragment>
          ))}
        </CardContent>
      </Card>

      <BasicButton
        variant="contained"
        fullWidth
        sx={{ 
          mt: 4,
          py: 1.5, 
          fontSize: '1.1rem', 
          fontWeight: 'bold',
          borderRadius: 3,
        }}
        onClick={handlePostUserGoals}
      >
        목표 설정 완료
      </BasicButton>
    </Container>
  );
}

export default SpendingGoalMain;
