import React, { useState } from 'react';

type UserInfoComponentsProps = {
  name: string;
  description: string;
  onChange: (value: string) => void; // New prop for change handler
};

function UserInfoComponents({ name, description: initialDescription, onChange }: UserInfoComponentsProps) {
  const [description, setDescription] = useState(initialDescription);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    onChange(e.target.value); // Call the onChange function when the input value changes
  };

  return (
    <div className="px-5 py-3 cursor-pointer transition-colors">
      <div className="flex flex-col space-y-2">
        <div className="text-sm font-medium text-gray-500">
          {name}
        </div>
        <input
          type="text"
          value={description}
          onChange={handleInputChange}
          placeholder="비어있음"
          className="text-sm font-medium text-gray-900 bg-gray-100 p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
  );
}

export default UserInfoComponents;
