import { Box, Button, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpendingItem from "./components/SpendingItem";
import { SpendingItemType } from "../../utils/enumUtil";
import ToggleTab, { TabType } from "../../components/ToggleTab";
import { bankingApi, TransactionItem } from "../../api/bankingApi";
import { userApi } from "../../api/userApi";
import { formatDateToMonthDay, formatTimeToHourMinute } from "../../utils/formatUtil";

function HomeTopWorstSpendDetail() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<TabType>('best');
  const [bestTransActions, setBestTransActions] = useState<TransactionItem[]>([]);
  const [worstTransActions, setWorstTransActions] = useState<TransactionItem[]>([]);
  const [consumptionPrediction, setConsumptionPrediction] = useState<number>(0);
  const curMonth = new Date().getMonth() + 1;

  const FetchBestTransactions = async () => {
    try {
      const userBestTransaction = await bankingApi.getBestTransActionTop3();

      if (userBestTransaction != null) {
        setBestTransActions(userBestTransaction);
      }

    } catch (error) {
      console.error('계좌 잔액을 불러오는 데 실패했습니다:', error);
    } finally {

    }
  };

  const FetchWorstTransactions = async () => {
    try {
      const userWorstTransaction = await bankingApi.getWorstTransActionTop3();

      if (userWorstTransaction != null) {
        setWorstTransActions(userWorstTransaction);
      }

    } catch (error) {
      console.error('계좌 잔액을 불러오는 데 실패했습니다:', error);
    } finally {

    }
  };

  const FetchConsumptionPrediction = async () => {
    try {
      const prediction = await userApi.getUserConsumptionPrediction();

      if (prediction != null) {
        setConsumptionPrediction(prediction);
      }

    } catch (error) {
      console.error('계좌 잔액을 불러오는 데 실패했습니다:', error);
    } finally {

    }
  };

  useEffect(() => {
    FetchBestTransactions();
    FetchWorstTransactions();
    FetchConsumptionPrediction();
    console.log(curMonth);
  }, []);

  return (
    <div style={{ background: '#' }}>
      {/* <Paper elevation={1} sx={{ margin: '0px 30px', padding: '20px', borderRadius: '20px' }}>
        <h5>{curMonth}월 소비 생활</h5>
        <h2 style={{ fontSize: '24px' }}>
          성공 확률{' '}
          <span style={{ fontWeight: 'bold', color: 'red' }}>
            {consumptionPrediction}%
          </span>
        </h2>
        <h5>벼락 맞고 생존할 확률과 같습니다!</h5>
      </Paper> */}
      <div style={{ margin: '20px 30px 0px' }}>
        <ToggleTab activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      {activeTab == 'best' ? (
        <Stack direction='column' sx={{ margin: '20px' }}>
          {bestTransActions.map((transAction) => (
            <SpendingItem
              key={transAction.id}
              SpendingItemTitle={transAction.description}
              SpendingItemTime={formatTimeToHourMinute(transAction.consumptionTime)}
              SpendingItemDate={formatDateToMonthDay(transAction.consumptionTime)}
              SpendingAmount={transAction.amount.toLocaleString()}
              SpendingCategory={transAction.type}
              CumulativeSpending={0}
              SuccessAttribution={transAction.successContribution}
              SuccessProbability={transAction.successProbability}
              ItemType={SpendingItemType.Plain}
              transactionAfterBalance={'0'} />
          ))}
        </Stack>
      ) : (
        <Stack direction='column' sx={{ margin: '20px' }}>
          {worstTransActions.map((transAction) => (
            <SpendingItem
              key={transAction.id}
              SpendingItemTitle={transAction.description}
              SpendingItemTime={formatTimeToHourMinute(transAction.consumptionTime)}
              SpendingItemDate={formatDateToMonthDay(transAction.consumptionTime)}
              SpendingAmount={transAction.amount.toLocaleString()}
              SpendingCategory={transAction.type}
              CumulativeSpending={0}
              SuccessAttribution={transAction.successContribution}
              SuccessProbability={transAction.successProbability}
              ItemType={SpendingItemType.Plain}
              transactionAfterBalance={'0'} />
          ))}
        </Stack>
      )}
    </div>
  );
};

export default HomeTopWorstSpendDetail;
